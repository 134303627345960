/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import Hotkeys from 'react-hot-keys';
import SetupDetails from './setupDetails/setupDetails';
import DeliveryAddress from './deliveryAddress/deliveryAddress';
import DeliveryOptions from './deliveryOptions/deliveryOptions';
import InternationalDeliveryOption from '../../../components/addShipment/DeliveryOptions/InternationalDeliveryOption';
import ProductAndServiceDetails from './productAndServiceDetails/productAndServiceDetails';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import newDomesticConsignmentForm from '../../../utils/formConfig/newDomesticConsignmentForm/newDomesticConsignmentForm';
import CustomAccordion from '../../../framework/accordion/customAccordion';
import ReturnOptions from './returnOptions/returnOptions';
import AdditionalInfo from './additionalInfo/additionalInfo';
import PromptJSPMClientInstallation from '../../../components/addShipment/Printing/PromptJSPMClientInstallation';
import CustomDialog from '../../../framework/dialog/customDialog';
import { abortOnGoingCallsFor, axiosConfig, GET, POST, PUT } from '../../../api/axios';
import { useHistory } from 'react-router';
import './addShipment.scss';
import { useDispatch } from 'react-redux';
import ParcelDetails from './parcelDetails/parcelDetails';
import CustomsDeclaration from './customsDeclaration/customsDeclaration';
import Favourite from './favourite';
import {
  formatAddToFavReqObj,
  formatCurrenciesToDisplay,
  createShipmentRequest,
  dynamicSort,
  setValuesToShipmentForm,
  resetFormAndKeepInitialValues,
  saveOrderReqObj,
  removeUndefinedProperties,
  getCustomerAccountID,
  createAmendRequest
} from '../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import AddToAddressAndFav from '../../../components/helperComponents/addToAddressAndFav';
import Templates from '../../../components/addShipment/templates/templates';
import * as actions from '../../../store/actions/index';
import { printZplLabels, printPDF, downloadPDF } from '../../../utils/PrintHelper';
import ConfirmationDialog from '../../../components/addressBook/confirmation/confirmation';
import CustomModal from '../../../framework/dialog/customDialog';
import ClearanceDeclaration from './clearanceDeclaration/clearanceDeclaration';

const { formId, formField } = newDomesticConsignmentForm;
const formikRef = React.createRef();
let reqFields = [
  'postalCode',
  'recipientName',
  'businessName',
  'addressLine1',
  'town',
  'emailAddress',
  'customerNumber',
  'weight',
  'reasonForExport',
  'commodityDetails',
  'product',
  'service',
  'commodityDetails',
  'extendedCoverDomestic',
  'specialInstructions'
];

let isPreview = false;
let isReviewOrder = false;
let orderSetupResponse = null;

const AddShipment = (props) => {
  const dispatch = useDispatch();
  const { id, isCollectionRequest, isvalid, shipmentId } = useParams();

  const { onLoad, onServicePoint } = props;
  const { genralConfiguration } = props;
  const [menuData, setMenuData] = React.useState({});
  const favouriteLimit = 100;
  const templateLimit = 100;
  const [countries, setCountries] = React.useState([]);
  const [customerNumber, setCustomerNumber] = React.useState('');
  const [currencies, setCurrencies] = React.useState([]);
  const [templateData, setTemplateData] = React.useState({});
  const [orderResponse, setOrderResponse] = React.useState({});
  const [shipmentResponse, setShipmentResponse] = React.useState();
  const [showDeliveryOptions, setShowDeliveryOptions] = React.useState(true);
  const [addressSearchObj, setAddressSearchObj] = React.useState({});
  const [isServicePointAddress, setIsServicePointAddress] = React.useState(false);

  const [openJSPMPrompt, setJSPMPrompt] = React.useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const [newDomestiValidationSchema, setValidationSchema] = React.useState(getYupSchemaFromMetaData(formField, [], []));
  const [printerConfigurationMessage, setprinterConfigurationMessage] = React.useState(false);
  const [showAllAddressFields, setShowAllAddressFields] = React.useState(false);
  const [servicePointAddress, setServicePointAddress] = React.useState({});
  const [clearanceDecleration, setClearanceDecleration] = React.useState({});
  const [populateClearance,setPopulateClearance] = React.useState(false);
  const [internationalProductList, setInternationalProductList] = useState([]);
  const [reasonsForExport, setReasonsForExport] = useState([]);
  isPreview = false;
  const [flagForAfterSubmitCheck, setFlagForAfterSubmitCheck] = React.useState(false);
  const [afterSubmitFlag, setAfterSubmitFlag] = useState(false);
  const history = useHistory();
  const initialValues = {};

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props?.type === 'checkbox' || field.props?.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props?.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }

  const isFormInValid = (errors, values) => {
    let clonedReqFields = [...reqFields];
    if (values?.destinationCountry?.postcodeOptional === true) {
      clonedReqFields.splice(0, 1);
    }
    if (values.isFullCustomDetails && values.destinationCountry.code !== 'GBR') {
      clonedReqFields.push(
        'customDeclarationReceiversEoriNo',
        'customDeclarationShippersEoriNo',
        'customDeclarationShippingCharges',
        'customDeclarationCurrency',
        'InvoiceNumber',
        'InvoiceDate',
        'telephone',
        // 'length',
        // 'width',
        // 'height',
      );
      if (values.applyIossNumber === 'true' && values.iossType === 'marketNo') {
        clonedReqFields.push('marketplaceIossNo');
      }
    } else if (!values.isFullCustomDetails && values.destinationCountry.code !== 'GBR') {
      clonedReqFields.push('descriptionOfGoods', 'customDeclarationValue');
      //clonedReqFields.push('length', 'width', 'height');
    }
    if (
      errors.addressLine1 !== undefined ||
      errors.addressLine2 !== undefined ||
      errors.addressLine3 !== undefined ||
      errors.postalCode !== undefined ||
      errors.town !== undefined ||
      errors.county  !== undefined ||
      (values.destinationCountry.code === 'USA' && errors.county !== undefined)
    ) {
      setShowAllAddressFields(true);
      return true;
    } else {
      setShowAllAddressFields(false);
    }
    if (values.isFullCustomDetails && errors.totalWeightExceed !==undefined){
      return true;
    }
    if (values.destinationCountry.code === 'USA') {
      clonedReqFields.push('county');
    }
    if (values.destinationCountry.code !== 'GBR' && values.intlParcelDetails.filter(x => x.isValid === false).length>0) {
      return true;
    }
    for (let err in errors) {
      if (clonedReqFields.some((fieldName) => fieldName === err)) {
        return true;
      }
    }
    if (values.destinationCountry.code === 'GBR' && values.createPalletShipment === 'yes' && errors.pallets) {
      return true;
    }
    if (errors.telephone !== undefined) {
      return true;
    }
    if (!values.product) {
      return true;
    }
    if (values.deliveryType === 'deliverToServicePoint' && !props.servicePointSelected?.servicePointID) {
      return true;
    }
    if (values.destinationCountry?.code2Digit == 'GB' &&
      values?.accountNumber?.sender?.postalCode?.substring(0, 2) != 'BT' &&
      values?.postalCode?.substring(0, 2) == 'BT') {
      if (values.shipmentMovement == '') {
        return true;
      }

      if (values.shipmentMovement.value == 'c2c') {
        if (values.descriptionOfGoods == '') {
          return true;
        } else if (values.clearanceTotalValue == '') {
          return true;
        }
      } else if (values.shipmentMovement.value == 'c2b') {
        if (values.receipentUKIMS != '' && values.receipentEORI == '') {
          return true;
        } else if (values.clearanceTotalValue == '') {
          return true;
        } else if(values?.commodityDetails?.length<1)
        {
          return true;
        }

      } else if (values.shipmentMovement.value == 'b2c') {
        if (values.shipperUKIMS != '' && values.shipperEORI == '') {
          return true;
        } else if (values.clearanceTotalValue == '') {
          return true;
        }else if(values?.commodityDetails?.length<1)
          {
            return true;
          }

      }
      else if (values.shipmentMovement.value == 'b2b') {
        if (values.shipperUKIMS != '' && values.shipperEORI == '') {
          return true;
        } else if (values.receipentUKIMS !== '' && values.receipentEORI == '') {
          return true;
        } else if (values.clearanceTotalValue == '') {
          return true;
        } 
        else if (values.receipentUKIMS == '' && values.shipperUKIMS == '') {
          if (values.reasonExport == '') {
            return true;
          } else if (values.shippingCharges == '') {
            return true;
          }
        }else if(values?.commodityDetails?.length<1)
          {
            return true;
          }

      }
    }
    //
    console.log(values)
    return false;
  };

  const save_order = (values, errors) => {
    const payLoad = saveOrderReqObj(orderResponse, values, props.servicePointSelected);
    PUT(ServiceEndPoints.saveOrder, payLoad)
      .then((response) => {
        let isScanFlag = false;
        if (history.location && history.location.state !== undefined && history.location.state !== null) {
          isScanFlag = history.location.state.isScanFlag;
        }
        if (response.data.isValid === true) {
          history.push({
            pathname: '/shipment/processOrders',
            state: {
              isCollectionRequest: false,
              isProcessOrder: true,
              isScanFlag: isScanFlag,
            },
          });
        } else {
          history.push({
            pathname: '/shipment/processOrders',
            state: {
              isCollectionRequest: false,
              isProcessOrder: false,
              isScanFlag: isScanFlag,
            },
          });
        }
        dispatch(actions.setAppSnackBarData({ msg: `Order updated successfully.` }));
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: `Error in creating order : ${err?.response?.data?.title}`,
              },
            ],
          }),
        );
        // dispatch(actions.setAppSnackBarData({ type: 'error', msg: `Error in creating order : ${err}` }));
      });
  };

  const handleSubmit = (values, errors, inboxReturn) => {
    setAfterSubmitFlag(true);
    if (!isFormInValid(errors, values)) {
      const alertMsgs = [];
      dispatch(actions.setLoaderLayOver(true));
      let addressPromise = Promise.resolve();
      if (!shipmentId) {
        if (values.addToAddressBook || values.updateAddToAddressBook) {
          const reqObj = formatAddToFavReqObj(values, props.userDetails, addressSearchObj);
          addressPromise = axiosConfig
            .post(ServiceEndPoints.postAddressBook, reqObj)
            .then((res) => {
              getFavCustomerList();
              dispatch(actions.setAppSnackBarData({ msg: 'Customer details stored successfully' }));
            })
            .catch((err) => {
              console.log(err);
              alertMsgs.push({ type: 'error', msg: 'Could not add/update customer details to addressbook.' });
            });
        } else if (values.addToFavCutomerList) {
          const { userId } = props.userDetails;
          const payload = [addressSearchObj.id];
          addressPromise = axiosConfig
            .post(`${ServiceEndPoints.postAddressBook}/favourite?userid=${userId}`, payload)
            .then((response) => { })
            .catch((err) => {
              // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'Could not add items to favourites.' }));
              alertMsgs.push({ type: 'error', msg: 'Could not add items to favourites.' });
            });
        }
      }
      let orderId = '';
      let isOrderRequest = false;
      if (id) {
        orderId = id;
        isOrderRequest = true;
      }
      const payLoad = !shipmentId ? createShipmentRequest(values, props.servicePointSelected, inboxReturn, isOrderRequest) :
        createAmendRequest(values);
      removeUndefinedProperties(payLoad);
      let manualShipment = false;
      if (!shipmentId && !id && orderSetupResponse?.outputFileSetUp?.manualShipment) {
        manualShipment = true;
      }
      var printFormat;
      if (props.printerConfiguration?.labelType === 1 && props.printerConfiguration?.isDowloadLabelAsPDF === false) {
        printFormat = 'ZPL';
      } else if (props.printerConfiguration?.labelType === 2) {
        printFormat = 'PDF';
      } else if (props.printerConfiguration?.isDowloadLabelAsPDF === true) {
        printFormat = 'PDF';
      } else {
        printFormat = 'PDF';
      }

      let pageSize = props.printerConfiguration?.pageSize ? props.printerConfiguration?.pageSize : 0;
      // const printFormat = props.printerConfiguration?.labelType === 1 ? 'ZPL' : 'PDF';
      const selctedPrinter = props.availablePrinter?.findIndex((x) => x === props.printerConfiguration?.labelPrinter);
      const invoiceReq =
        genralConfiguration?.printCustomInvoice && values.destinationCountry.code !== 'GBR'
          ? '&isCustomsInvoice=true'
          : '';
      const shipmentPromise = axiosConfig
        .post(
          `${shipmentId ? ServiceEndPoints.shipmentAmmendUrl : ServiceEndPoints.shipmentLabelUrl}?includeLabel=INCLUDE&format=${printFormat}&pageSize=${values.destinationCountry?.code !== 'GBR' ? 0 : pageSize
          }&orderId=${orderId}&isOrderRequest=${isOrderRequest}${invoiceReq}&shipmentId=${shipmentId ?? ""}&manualShipment=${manualShipment}`,
          payLoad,
        )
        .then((response) => {
          dispatch(actions.setServiceLocationDetails(null));
          isPreview = false;
          resetFormAndKeepInitialValues(
            formikRef.current.resetForm,
            formikRef.current.setFieldValue,
            countries,
            formikRef.current.values?.accountNumber,
            setFlagForAfterSubmitCheck,
          );
          formikRef.current.setFieldValue('DispatchDate', '');
          formikRef.current.setFieldValue('createPalletShipment', 'no');
          dispatch(actions.resetReturnReferenceParams());
          if (!props.printerConfiguration?.isDowloadLabelAsPDF && props.JSPMStatus !== null && !props.JSPMStatus) {
            setJSPMPrompt(true);
          } else {
            let shipment = response.data.shipments[0];
            (shipment?.labels ?? []).map((label, index) => {
              if (props.printerConfiguration?.isDowloadLabelAsPDF === true) {
                let lableName = '';
                if (index === 0) {
                  lableName = '_Label.pdf';
                } else if (index === 1) {
                  lableName = '_ReturnLabel.pdf';
                } else {
                  lableName = '_ReturnInstructions.pdf';
                }
                downloadPDF(label, lableName, response.data);
              } else {
                if (props.printerConfiguration?.labelType == 1) {
                  printZplLabels(label, props.printerConfiguration?.labelPrinter);
                } else {
                  printPDF(label, props.printerConfiguration?.labelPrinter, 'Label');
                }
              }
            });
            if (values.destinationCountry.code !== 'GBR' && genralConfiguration?.printCustomInvoice) {
              response?.data?.shipments?.[0]?.invoices?.map((invoice, index) => {
                if (props.printerConfiguration?.isDowloadLabelAsPDF === true) {
                  let lableName = '';
                  if (index === 0) {
                    lableName = '_CustomsInvoice.pdf';
                  } else if (index === 1) {
                    lableName = '_CustomsInvoice.pdf';
                  } else {
                    lableName = '_CustomsInvoice.pdf';
                  }
                  downloadPDF(invoice, lableName, response.data);
                } else {
                  printPDF(invoice, props.printerConfiguration?.otherReportsPrinter, 'Invoice');
                }
              });
            }
          }
          shipmentId && dispatch(actions.setAppSnackBarData({ msg: `Shipment ${shipmentId} amended successfully` }));
          if (id) {
            let isScanFlag = false;
            if (history.location && history.location.state !== undefined && history.location.state !== null) {
              isScanFlag = history.location.state.isScanFlag;
            }
            history.push({
              pathname: '/shipment/processOrders',
              state: {
                isCollectionRequest: false,
                isProcessOrder: true,
                isScanFlag: isScanFlag,
              },
            });
          }
          else {
            history.push("/shipment/addShipment");
          }
        })
        .catch((err) => {
          console.log(err);
          alertMsgs.push({
            type: 'error',
            msg: `Error in creating shipment : ${err.response?.data[0]?.detail ?? 'Error occurred while creating shipment, please try again'
              }`,
          });
          // dispatch(
          //   actions.setBottomAlert({
          //     msgs: [
          //       {
          //         type: 'error',
          //         msg: `Error in creating shipment : ${err.response?.data?.[0]?.detail}`,
          //       },
          //     ],
          //   }),
          // );
        })
        .finally(() => {
          dispatch(actions.setLoaderLayOver(false));
        });
      Promise.all([shipmentPromise, addressPromise]).then(() => {
        if (alertMsgs.length > 0) {
          dispatch(
            actions.setBottomAlert({
              msgs: alertMsgs,
            }),
          );
        }
      });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }],
        }),
      );
    }
  };

  const handlePreview = (values, errors) => {
    isPreview = true;
    dispatch(actions.setAddShipmentData(values));
    dispatch(actions.setOrderResponse(orderResponse));
  };

  useEffect(() => {
    if (id && Object.keys(orderResponse).length < 1 && Object.keys(props.orderResponse).length > 1) {
      setOrderResponse(props.orderResponse);
    }
  }, [id]);

  useEffect(() => {
    if (genralConfiguration && genralConfiguration.customerReferenceMandetory) {
      formField.customerReference.props.isRequired = true;
      formField.customerReference.validation.validations.push({
        type: 'required',
        params: ['Reference cannot be empty'],
      });
      reqFields.push(formField.customerReference.props.name);
    } else {
      formField.customerReference.validation.validations = formField.customerReference.validation.validations.filter(
        (x) => x.type !== 'required',
      );
      formField.customerReference.props.isRequired = false;
    }
    if (genralConfiguration && genralConfiguration.alternativeReferenceMandatory || (formikRef.current?.values.postalCode.substring(0,2).toUpperCase() === 'GY' || formikRef.current?.values.postalCode.substring(0,2).toUpperCase()  === 'JE')) {
      formField.alternateReference.props.isRequired = true;
      if(formikRef.current?.values.postalCode.substring(0,2).toUpperCase() === 'GY' || formikRef.current?.values.postalCode.substring(0,2).toUpperCase()  === 'JE')
      {
        formField.alternateReference.validation.validations.pop();
        formField.alternateReference.validation.validations.push({
          type: 'required',
          params: ['Description & value is required'],
        });
      }
      else{
        formField.alternateReference.validation.validations.push({
          type: 'required',
          params: ['Reference cannot be empty'],
        });
      }
      reqFields.push(formField.alternateReference.props.name);
    }
    else {
      formField.alternateReference.validation.validations = formField.alternateReference.validation.validations.filter(
        (x) => x.type !== 'required',
      );
      formField.alternateReference.props.isRequired = false;
    }
    if (genralConfiguration && genralConfiguration.reference1 && genralConfiguration.reference1 !== '') {
      formField.customerReference.props.label = genralConfiguration.reference1;
    }
    if(formikRef.current?.values.postalCode.substring(0,2).toUpperCase() === 'GY' || formikRef.current?.values.postalCode.substring(0,2).toUpperCase()  === 'JE')
    {
      formField.alternateReference.props.label = 'Description & value';
    }
    else if (genralConfiguration && genralConfiguration.reference2 ) {
      formField.alternateReference.props.label = genralConfiguration.reference2;
    }
    else{
      formField.alternateReference.props.label = 'Alternate reference';
    }
    updateValidationSchema();
  }, [genralConfiguration, formikRef.current?.values.postalCode]);

  const getFavCustomerList = (value = '') => {
    abortOnGoingCallsFor(ServiceEndPoints.getFavouritesForByText);
    axiosConfig
      .get(
        `${ServiceEndPoints.getFavouritesForByText
        }?organization=${getCustomerAccountID()}&offSet=0&limit=${favouriteLimit}&userid=${props.userDetails.userId
        }&text=${value}`,
      )
      .then((res) => {
        setMenuData(res.data);
      })
      .catch((err) => {
        setMenuData([]);
        console.log(err);
      });
  };

  const getTemplates = (value = '') => {
    abortOnGoingCallsFor(ServiceEndPoints.getTemplateBy);
    axiosConfig.get(
      `${ServiceEndPoints.getTemplateBy
      }?organization=${getCustomerAccountID()}&offSet=0&limit=${templateLimit}&templateName=${value}`,
    )
      .then((res) => {
        setTemplateData(res.data);
      })
      .catch((err) => {
        setTemplateData([]);
        console.log(err);
      });
  };

  useEffect(() => {
    if (props?.userDetails?.userId) {
      GET(ServiceEndPoints.getCountries)
        .then((res) => {
          setCountries(res.data.countries.sort(dynamicSort('name')));
        })
        .catch((err) => {
          setCountries([]);
        });
      GET(ServiceEndPoints.getCurrencies)
        .then((res) => {
          setCurrencies(formatCurrenciesToDisplay(res.data.currencies));
        })
        .catch((err) => {
          setCurrencies([]);
        });
      getFavCustomerList();
      getTemplates();
      if (
        (history.location.state?.fromPreview !== true &&
          id !== undefined &&
          isCollectionRequest !== undefined &&
          isvalid !== undefined)
      ) {
        populateAddShipment();
      }
      else if (history.location.state?.fromPreview !== true && shipmentId) {
        populateAmmendShipment();
      }
    }
  }, [props.userDetails]);

  useEffect(() => {
    if (
      (countries &&
        currencies &&
        countries.length > 0 &&
        currencies.length > 0 &&
        (Object.keys(orderResponse).length > 0 && props.loadedFromData?.loadedFrom !== "fromPreviewSummary") &&
        id !== undefined &&
        isCollectionRequest !== undefined &&
        isvalid !== undefined) || shipmentResponse
    ) {
      if (shipmentResponse) { orderResponse.shipmentModel = shipmentResponse; }
      const formatedResponse = {
        ...orderResponse.shipmentModel,
      };
      orderResponse.shipmentModel.shipments[0].shipmentDetails.doorStepInstructions = formatedResponse?.product
        ?.neighbourDelivery
        ? 'deliverToNeighbour'
        : formatedResponse?.product?.secureLocationDelivery
          ? 'leaveSafe'
          : 'deliverToDoorStepOnly';
      formikRef.current.setValues(
        setValuesToShipmentForm(
          orderResponse.shipmentModel,
          formikRef.current.values,
          countries,
          currencies,
          props.userDetails,
        ),
      );
      formatedResponse['deliveryOptions'] = {
        doorStepInstructions: formatedResponse?.product?.neighbourDelivery
          ? 'deliverToNeighbour'
          : formatedResponse?.product?.secureLocationDelivery
            ? 'leaveSafe'
            : 'deliverToDoorStepOnly',
      };
      formatedResponse.product = orderResponse.shipmentModel.product?.productCode;
      formatedResponse.service = orderResponse.shipmentModel.service?.description;
      formatedResponse['accountNumber'] = { accountId: orderResponse?.shipmentModel.pickupAccount };
      formatedResponse.fromAddship = true;
      onLoad({
        loadedFrom: 'dropdownResponse',
        loadedResponse: { ...formatedResponse, ...orderResponse },
      });
      setTimeout(() => {
        dispatch(actions.setLoaderLayOver(false));
      }, 2500);
      shipmentResponse && setTimeout(() => {
        if (props.loadedFromData?.loadedFrom !== "fromPreviewSummary") {
          onLoad({ loadedFrom: '' })
        }
      }, 7000);
      setTimeout(() => {
        if (props.loadedFromData?.loadedFrom !== "fromPreviewSummary") {
          onLoad({ loadedFrom: '' })
        }
      }, 12000);
      if (orderResponse.shipmentModel.shipments[0]?.consigneeAddress?.addressType?.toLowerCase() === 'servicepoint') {
        let consigneeAddress = orderResponse.shipmentModel.shipments[0]?.consigneeAddress;
        let values = {
          servicePointID: consigneeAddress.locationId,
          address: {
            businessName: consigneeAddress.companyName,
            address1: consigneeAddress.address1,
            address2: consigneeAddress.address2,
            address3: consigneeAddress.address3,
            postalTown: consigneeAddress.city,
            zipcode: consigneeAddress.postalCode,
          },
        };
        setServicePointAddress(values);
        dispatch(actions.setServiceLocationDetails(values));
      }
    
      if (orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations) {
        let value = {
          descriptionOfGoodsShipment:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.descriptionOfGoods,
          numberOfItems:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.numberOfItems,
          reasonForExport:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.reasonForExport,
          recipientEORINumber:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.recipientEORINumber,
          recipientUKIMSNumber:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.recipientUKIMSNumber,
          sendersDefermentAccount:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.sendersDefermentAccount,
          sendersEORINumber:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.sendersEORINumber,
          sendersUKIMSNumber:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.sendersUKIMSNumber,
          shipmentMovementType:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.shipmentMovementType,
          shippingCharges:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.shippingCharges,
          totalValue:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.totalValue,
          items:orderResponse.shipmentModel.shipments[0]?.clearanceDeclarations?.items,
          itemCountry:countries
        }
        setPopulateClearance(true);
        setClearanceDecleration(value);
      }
    }
  }, [countries, currencies, orderResponse, shipmentResponse]);
  const populateAddShipment = () => {
    dispatch(actions.setLoaderLayOver(true));
    GET(
      `${ServiceEndPoints.getOrders
      }?organization=${getCustomerAccountID()}&id=${id}&isValid=${isvalid}&isCollectionRequest=${isCollectionRequest}`,
    )
      .then((res) => {
        setOrderResponse(res.data?.orders?.[0]);
        if (res.data?.orders?.[0]?.errors?.length > 0) {
          isReviewOrder = true;
          const errorMessages = res.data.orders[0].errors.map((val) => {
            return {
              type: 'error',
              msg: val,
            };
          });
          errorMessages.length > 0 && dispatch(actions.setLoaderLayOver(false));
          dispatch(
            actions.setBottomAlert({
              msgs: errorMessages,
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setLoaderLayOver(false));

      })
  };

  const populateAmmendShipment = () => {
    dispatch(actions.setLoaderLayOver(true));
    GET(
      `${ServiceEndPoints.shipmentLabelUrl}?shipmentId=${shipmentId}`,
    )
      .then((res) => {
        setShipmentResponse(res.data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setLoaderLayOver(false));

      })
  };

  const handleClose = () => {
    setJSPMPrompt(false);
    setprinterConfigurationMessage(false);
  };

  const handleCancel = (resetForm, setFieldValue, formikValues) => {
    if (id && isCollectionRequest && isvalid) {
      setShowConfirmationDialog(true);
    } else {
      resetFormAndKeepInitialValues(resetForm, setFieldValue, countries, formikValues.accountNumber);
    }
    dispatch(actions.resetReturnReferenceParams());
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const onDialogConfirm = () => {
    let isScanFlag = false;
    if (history.location && history.location.state !== undefined && history.location.state !== null) {
      isScanFlag = history.location.state.isScanFlag;
    }
    history.push({
      pathname: '/shipment/processOrders',
      state: {
        isCollectionRequest: false,
        isProcessOrder: !isReviewOrder,
        isScanFlag: isScanFlag,
      },
    });
  };

  const updateValidationSchema = () => {
    setValidationSchema(getYupSchemaFromMetaData(formField, [], []));
  };

  const getOrderSetupResponse = () => {
    GET(`${ServiceEndPoints.orderSetup}?organization=${getCustomerAccountID()}`)
      .then((res) => {
        orderSetupResponse = res.data
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrderSetupResponse();
    if (
      props?.printerConfiguration &&
      !props.printerConfiguration?.isDowloadLabelAsPDF &&
      props.JSPMStatus !== null &&
      !props.JSPMStatus
    ) {
      setJSPMPrompt(true);
    }
    return () => {
      if (!isPreview) {
        dispatch(actions.setServiceLocationDetails(null));
        dispatch(actions.setAddShipmentData(null));
        dispatch(actions.setOrderResponse({}));
        dispatch(actions.setReturnReferenceCount(0));
        dispatch(actions.setIsReturnReferenceUpdated(false));
      }
    };
  }, []);

  useEffect(() => {
    if (!shipmentId && !id) {
      resetFormAndKeepInitialValues(formikRef.current.resetForm, formikRef.current.setFieldValue, countries, formikRef.current.values?.accountNumber);
      dispatch(actions.resetReturnReferenceParams());
    }
  }, [shipmentId])

  const getCorrectData = () => {
    if (props.printerConfiguration === undefined) {
      // to check if printer configuration call is success
      setprinterConfigurationMessage(false);
      setJSPMPrompt(false);
    } else {
      if (props?.printerConfiguration?.isDowloadLabelAsPDF) {
        setprinterConfigurationMessage(false);
        setJSPMPrompt(false);
      } else {
        if (!props.JSPMStatus) {
          setJSPMPrompt(true);
        } else if (!props?.printerConfiguration?.labelPrinter?.trim()) {
          setprinterConfigurationMessage(true);
        } else {
          setprinterConfigurationMessage(false);
          setJSPMPrompt(false);
        }
      }
    }
  };
  useEffect(() => {
    getCorrectData();
  }, [
    props.printerConfiguration,
    props.printerConfiguration?.labelPrinter,
    props.printerConfiguration?.otherReportsPrinter,
    props.JSPMStatus,
    props?.printerConfiguration?.isDowloadLabelAsPDF,
  ]);

  const onKeyUp = (keyName, e, handle) => {
    if (keyName === 'f2') {
      document.getElementById('downloadAsPDF').click();
    } else if (keyName === 'esc') {
      if (id) {
        document.getElementById('btnCancel').click();
      }
    }
  };
  return (
    <Grid className="shipment">
      <Grid>
        <React.Fragment>
          <Hotkeys keyName="f2,esc" onKeyUp={onKeyUp.bind(this)}>
            <Formik
              initialValues={initialValues}
              // onSubmit={handleSubmit}
              validationSchema={newDomestiValidationSchema}
              enableReinitialize={true}
              innerRef={formikRef}
            >
              {({ values, setValues, setFieldValue, setFieldTouched, errors, setErrors, resetForm }) => {
                let showReturnLabel = values?.accountNumber?.returnConfiguration?.inboxReturnAllowed;
                const isDomestic = values?.destinationCountry?.code === 'GBR';
                if ((values.noOfItems > 1 || values.weight > 20) && isDomestic) {
                  showReturnLabel = false;
                }
                const inBoxReturnAvailable = values?.product?.deliveryCapabilities?.inBoxReturn ?? false;
                return (
                  <Form id={formId} noValidate>
                    <Grid container direction="row" justify="flex-end" className="addShipment-container" spacing={0}>
                      <Grid item style={{ marginLeft: '2%' }}>
                        <Templates
                          formField={formField}
                          formikValues={values}
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          errors={errors}
                          currencies={currencies}
                          templateData={templateData}
                          resetForm={resetForm}
                          isDomestic={isDomestic}
                          countries={countries}
                          getTemplates={getTemplates}
                          templateLimit={templateLimit}
                          isAmendShipment={shipmentId !== undefined}
                        />
                      </Grid>
                      <Grid item>
                        <Favourite
                          formField={formField}
                          countries={countries}
                          favouriteValues={menuData}
                          formikValues={values}
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          errors={errors}
                          setCustomerNumber={setCustomerNumber}
                          currencies={currencies}
                          getFavCustomerList={getFavCustomerList}
                          favouriteLimit={favouriteLimit}
                          setAddressSearchObj={setAddressSearchObj}
                          isAmendShipment={shipmentId !== undefined}
                          setIsServicePointAddress={setIsServicePointAddress}
                        />
                      </Grid>
                    </Grid>

                    <CustomAccordion heading="Setup" className="addShipmentPage">
                      <SetupDetails
                        formField={formField}
                        countries={countries}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        setCustomerNumber={setCustomerNumber}
                        setValues={setValues}
                        currencies={currencies}
                        flagForAfterSubmitCheck={flagForAfterSubmitCheck}
                        setFlagForAfterSubmitCheck={setFlagForAfterSubmitCheck}
                        setAddressSearchObj={setAddressSearchObj}
                        id={id}
                        shipmentId={shipmentId}
                        isAmendShipment={shipmentId !== undefined}
                        setIsServicePointAddress={setIsServicePointAddress}
                      />
                    </CustomAccordion>

                    <CustomAccordion heading="Parcel details">
                      <ParcelDetails
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        setErrors={setErrors}
                        isDomestic={isDomestic}
                        setFieldTouched={setFieldTouched}
                        updateValidationSchema={updateValidationSchema}
                        setShowDeliveryOptions={setShowDeliveryOptions}
                        isAmendShipment={shipmentId !== undefined}
                        setReasonsForExport = {setReasonsForExport}
                        setAfterSubmitFlag={setAfterSubmitFlag}
                        afterSubmitFlag={afterSubmitFlag}
                        setIsServicePointAddress={setIsServicePointAddress}
                        isServicePointAddress={isServicePointAddress}
                        {...props}
                      />
                    </CustomAccordion>
                    <CustomAccordion heading="Delivery address">
                      <DeliveryAddress
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        setValues={setValues}
                        setCustomerNumber={setCustomerNumber}
                        updateValidationSchema={updateValidationSchema}
                        showAllAddressFields={showAllAddressFields}
                        setShowAddressFields={() => {
                          setShowAllAddressFields(false);
                        }}
                        setFieldTouched={setFieldTouched}
                        isAmendShipment={shipmentId !== undefined}
                      />
                    </CustomAccordion>
                   
                   {console.log(values?.accountNumber?.sender?.country,
                     values?.accountNumber?.sender?.postalCode?.substring (0,2),
                      values?.postalCode?.substring (0,2))}
                    {/* {values.destinationCountry?.code2Digit == 'GB' &&
                     values?.accountNumber?.sender?.postalCode?.substring (0,2) != 'BT' &&
                      values?.postalCode?.substring (0,2) == 'BT' &&  */}
                     { values?.accountNumber?.sender?.country == 'GB' &&
                     values?.accountNumber?.sender?.postalCode?.substring (0,2) != 'BT' &&
                      values?.postalCode?.substring (0,2) == 'BT' && 
                      (
                    <CustomAccordion heading={'Clearance Declaration'}>
                      <ClearanceDeclaration
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        isDomestic={isDomestic}
                        servicePointAddress={servicePointAddress}
                        clearanceDecleration={clearanceDecleration}
                        updateValidationSchema={updateValidationSchema}
                        isAmendShipment={shipmentId !== undefined}
                        isFromProcessOrder={id !== undefined}
                        populateClearance={populateClearance}
                      />
                    </CustomAccordion>
                     )} 
                    {isDomestic ? (
                      <CustomAccordion heading={'Delivery options'}>
                        <DeliveryOptions
                          formField={formField}
                          formikValues={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          isDomestic={isDomestic}
                          servicePointAddress={servicePointAddress}
                          updateValidationSchema={updateValidationSchema}
                          isAmendShipment={shipmentId !== undefined}
                          isFromProcessOrder={id !== undefined}
                        />
                      </CustomAccordion>
                    ) : showDeliveryOptions && values.addressType === 'residential' ? (
                      <CustomAccordion heading={'Delivery options'}>
                        <InternationalDeliveryOption
                          formField={formField}
                          formikValues={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          isDomestic={isDomestic}
                        />
                      </CustomAccordion>
                    ) : null}

                    {isDomestic ? (
                      <CustomAccordion heading={isDomestic ? 'Product and service' : 'Product'}>
                        <ProductAndServiceDetails
                          internationalProductList={internationalProductList}
                          setInternationalProductList={setInternationalProductList}
                          formField={formField}
                          formikValues={values}
                          setFieldValue={setFieldValue}
                          isDomestic={isDomestic}
                          errors={errors}
                        />
                      </CustomAccordion>
                    ) : null}

                    <CustomAccordion heading="Additional information about your shipment">
                      <AdditionalInfo
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        isDomestic={isDomestic}
                        loadedFrom={props.loadedFromData}
                      />
                    </CustomAccordion>
                    {showReturnLabel && inBoxReturnAvailable && (
                      <CustomAccordion heading="Return option">
                        <ReturnOptions formField={formField} formikValues={values} setFieldValue={setFieldValue} isAmendShipment={shipmentId} />
                      </CustomAccordion>
                    )}
                    {(values.destinationCountry || {}).code !== 'GBR' ? (
                      <React.Fragment>
                        <CustomAccordion heading="Customs declaration">
                          <CustomsDeclaration
                            internationalProductList={internationalProductList}
                            setInternationalProductList={setInternationalProductList}
                            reasonsForExport={reasonsForExport}
                            formField={formField}
                            formikValues={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            currencies={currencies}
                            isDomestic={isDomestic}
                          />
                        </CustomAccordion>
                        <CustomAccordion heading={isDomestic ? 'Product and service' : 'Product'}>
                          <ProductAndServiceDetails
                            internationalProductList={internationalProductList}
                            setInternationalProductList={setInternationalProductList}
                            reasonsForExport={reasonsForExport}
                            formField={formField}
                            formikValues={values}
                            setFieldValue={setFieldValue}
                            isDomestic={isDomestic}
                            errors={errors}
                          />
                        </CustomAccordion>
                      </React.Fragment>
                    ) : null}
                    <Grid item xs={6}>
                      <AddToAddressAndFav
                        formField={formField}
                        formikValues={values}
                        customerNumber={customerNumber}
                        setFieldValue={setFieldValue}
                        addToAddressBookControl="addToAddressBook"
                        addToFavControlName="addToFavCutomerList"
                        updateAddressBookControl="updateAddToAddressBook"
                        customerNumberControl="customerNumber"
                        addressSearchObj={setAddressSearchObj}
                        shipmentDetails={props.shipmentDetails}
                        isAmendShipment={shipmentId !== undefined}
                      />
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-end"
                      alignItems="flex-end"
                      style={{ marginTop: '-3%' }}
                    >
                      <Grid item>
                        <InputButton
                          label={id ? 'Cancel - Esc' : 'Clear'}
                          variant="text"
                          onClick={() => handleCancel(resetForm, setFieldValue, values)}
                          className="text-btn"
                          id="btnCancel"
                        />
                      </Grid>
                      <Grid item>
                        <InputButton
                          variant="outlined"
                          className="outlined-btn"
                          component={NavLink}
                          to={{
                            pathname: '/shipment/addShipment/preview',
                            state: { ...history.location.state, prevPath: history.location.pathname },
                          }}
                          label="Preview "
                          onClick={() => {
                            handlePreview(values, errors);
                          }}
                        />
                      </Grid>
                      {id ? (
                        <Grid item>
                          <InputButton
                            variant="contained"
                            className="contained-btn"
                            onClick={() => {
                              save_order(values, errors);
                            }}
                            // buttonType="submit"
                            label="Save"
                          />
                        </Grid>
                      ) : null}
                      <Grid item>
                        <InputButton
                          id="downloadAsPDF"
                          download={props.printerConfiguration?.isDowloadLabelAsPDF === true ? 'kjsdflhsaj.pdf' : null}
                          variant="contained"
                          className="contained-btn"
                          onClick={() => {
                            handleSubmit(values, errors, showReturnLabel && inBoxReturnAvailable);
                          }}
                          buttonType="submit"
                          label="Submit & print label - F2"
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Hotkeys>
        </React.Fragment>
        <CustomDialog
          open={(openJSPMPrompt && props.JSPMStatus !== null) || printerConfigurationMessage}
          onClose={handleClose}
          title={printerConfigurationMessage === true ? 'Printer configuration' : 'Printer setup'}
        >
          <PromptJSPMClientInstallation printerConfiguration={printerConfigurationMessage} />
        </CustomDialog>
        <Grid container item className="customModal-book">
          <CustomModal
            open={showConfirmationDialog}
            title="Cancel order request"
            onClose={handleDialogClose}
            maxWidth="sm"
          >
            <ConfirmationDialog
              confirmationMessage="Are you sure you want to cancel? Any unsaved changes will be lost."
              onConfirm={onDialogConfirm}
              OnCancel={handleDialogClose}
              confirmLabel="Yes"
              cancelLabel="No"
            />
          </CustomModal>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddShipment.propTypes = {};

const mapStateToProps = (state) => {
  return {
    // selectedAccount: state.accounts.selectedAccount,
    // favouriteCustomers: state.customers.favouriteCustomers,
    shipmentDetails: state.addShipmentData.shipmentDetails,
    orderResponse: state.addShipmentData.orderResponseData,
    userDetails: state.auth.userDetails,
    servicePointSelected: state.addShipmentData.servicePointDelivery,
    JSPMStatus: state.common.isJSPMInstalled,
    printerConfiguration: state.common.printerConfiguration,
    availablePrinter: state.common.availablePrinter,
    genralConfiguration: state.common.commonConfiguration,
    loadedFromData: state.loadedFrom.loadedFrom,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (loadedFrom) => {
      dispatch(actions.setLoadedFrom(loadedFrom));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShipment);
