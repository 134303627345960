import React, { useState, useEffect } from 'react';
import { AppBar, FormControl, Grid, NativeSelect, Tab, Tabs } from '@material-ui/core';
import TabPanel from '../../../shared/tabPanel';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';

import SchemaMaterialTable from './SchemaMaterialTable';
import { removeDuplicates, sortBasedOnNestedOrNot } from '../../../../utils/helperFunctions';


const SchemaMappingGrid = (props) => {
  const {
    columns,
    multiTab,
    firstTab,
    secTab,
    thirdTab,
    customsValues,
    collectionValues,
    formikValues,
    mappingDetails,
    getFieldSchemaDetails,
    typeOfSchema,
    requiredField,
    setDropdownValues,
    dropdownValues,
    setCurrentValue,
    setCurrentValueStartLength,
    setCurrentRowIndicatorValue,
    setCurrentRowIndicatorPlacement,
    formField,
    isClear,
    setFieldValue,
    currentTab,
    setTabValue,
    isDefaultSchema,
    setIsClear,
    setFieldTouched
  } = props;

  let { orderValues } = props;

  if (!multiTab) {
    orderValues = orderValues.filter(order => order.name !== "Row Indicator")
  }

  const [dragFromValues, setDragFromValues] = useState(sortBasedOnNestedOrNot(orderValues, "name"));
  const [dragToValues, setDragToValues] = useState([]);
  const [dragFromDropDownValues, setDragFromDropDownValues] = useState([]);


  const [firstDragFromValues, setFirstDragFromValues] = useState(sortBasedOnNestedOrNot(orderValues, "name"));
  const [firstDragToValues, setFirstDragToValues] = useState([]);
  const [dropDownFirstDragFromValues, setDropDownFirstDragFromValues] = useState([]);


  const [secDragFromValues, setSecDragFromValues] = useState(sortBasedOnNestedOrNot(customsValues, "name"));
  const [secDragToValues, setSecDragToValues] = useState([]);
  const [dropDownSecDragFromValues, setDropDownSecDragFromValues] = useState([]);


  const [thirdDragFromValues, setThirdDragFromValues] = useState(sortBasedOnNestedOrNot(collectionValues, "name"));
  const [thirdDragToValues, setThirdDragToValues] = useState([]);
  const [dropDownThirdDragFromValues, setDropDownThirdDragFromValues] = useState([]);


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setCurrentValueStartLength([0, 0]);
    let currentTabIndicatorValue = formikValues[`rowIndicator${newValue + 1}`]?.trim()
    setCurrentRowIndicatorValue && setCurrentRowIndicatorValue(currentTabIndicatorValue);
    setCurrentValue("");
  };

  useEffect(() => {
    setFieldTouched('rowIndicator1', false);
    setFieldTouched('rowIndicator2', false);
    setFieldTouched('rowIndicator3', false);
  }, [currentTab])

  useEffect(() => {

    if (isClear) {
      setDragToValues([])
      setDragFromValues(sortBasedOnNestedOrNot(orderValues, "name"))
      setFirstDragToValues([])
      setFirstDragFromValues(sortBasedOnNestedOrNot(orderValues, "name"))

      setSecDragToValues([])
      setSecDragFromValues(sortBasedOnNestedOrNot(customsValues, "name"))

      setThirdDragToValues([])
      setThirdDragFromValues(sortBasedOnNestedOrNot(collectionValues, "name"))
      setIsClear(false)
    }
  }, [isClear])


  useEffect(() => {

    const firstMappingArray = [];
    const secMappingArray = []
    const thirdMappingArray = []

    const dropDownValues = [{ name: "Select", value: '' }]
    const firstDropDownValues = [{ name: "Select", value: '' }]
    const secDropDownValues = [{ name: "Select", value: '' }]
    const thirdDropDownValues = [{ name: "Select", value: '' }]



    if ((mappingDetails || {}).schemaType === "order" || (mappingDetails || {}).schemaType === "collection" || (mappingDetails || {}).schemaType === "custom" || (mappingDetails || {}).schemaType === "order+collectionsConsignor" || (mappingDetails || {}).schemaType === "parcel" || (mappingDetails || {}).schemaType === "clearance") {
      const fromValues = [...dragFromValues];
      (mappingDetails.fieldsMappingSchema || []).sort(function (a, b) {
        return a.mapFrom - b.mapFrom;
      }).map((filedDetails, index) => {
        const dropDownObj = {}
        const colName = "Col"
        if (fromValues.length > 0) {
          const fieldObject = fromValues.find(fromValues => fromValues.valueName === filedDetails.mapTo);
          if (fieldObject != undefined) {
            fieldObject['valueName'] = filedDetails.mapTo;
            fieldObject['columnFromFile'] = filedDetails.mapFrom;
            fieldObject['startsAt'] = filedDetails.range.startIndex;
            fieldObject['length'] = filedDetails.range.columnLength;
            fieldObject['type'] = filedDetails.type;
            if (filedDetails.mapFrom != '') {
              dropDownObj["id"] = index;
              dropDownObj["name"] = mappingDetails.isheaderIncluded ? filedDetails.mapFrom : colName.concat(filedDetails.mapFrom);
              dropDownObj["value"] = filedDetails.mapFrom;
            }
            const filteredValue = fromValues.findIndex(fromValues => fromValues.valueName === filedDetails.mapTo);
            fromValues.splice(filteredValue, 1);

            firstMappingArray.push(fieldObject)
            dropDownValues.push(dropDownObj)
          }
        }
      })
      setDragFromDropDownValues(removeDuplicates(dropDownValues, item => item.name))
      setDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"))


      setDragToValues(firstMappingArray)
    }
    if ((mappingDetails || {}).schemaType === "orders+customs" || (mappingDetails || {}).schemaType === "orders+collections"|| (mappingDetails || {}).schemaType === "orders+clearance") {
      const firstFromValues = [...firstDragFromValues];
      const secFromValues = [...secDragFromValues];


      (mappingDetails.fieldsMappingSchema || []).sort(function (a, b) {
        return a.mapFrom - b.mapFrom;
      }).map((filedDetails, index) => {
        // const fieldObject = {} 
        const dropDownObj = {}
        const colName = "Col"
        if (filedDetails.type === "order") {


          if (firstFromValues.length > 0) {
            const fieldObject = firstFromValues.find(firstFromValues => firstFromValues.valueName === filedDetails.mapTo);
            if (fieldObject != undefined) {
              fieldObject['valueName'] = filedDetails.mapTo;
              fieldObject['columnFromFile'] = filedDetails.mapFrom;
              fieldObject['startsAt'] = filedDetails.range.startIndex;
              fieldObject['length'] = filedDetails.range.columnLength;
              fieldObject['type'] = filedDetails.type;
              if (filedDetails.mapTo === 'rowindicator') {

                setFieldValue("rowIndicator1", filedDetails.value || '')

              }
              if (filedDetails.mapFrom != '') {
                dropDownObj["id"] = index;
                dropDownObj["name"] = colName.concat(filedDetails.mapFrom);
                dropDownObj["value"] = filedDetails.mapFrom;


              }
              firstMappingArray.push(fieldObject)
              const filteredValue = firstFromValues.findIndex(firstFromValues => firstFromValues.valueName === filedDetails.mapTo);
              firstDropDownValues.push(dropDownObj)
              firstFromValues.splice(filteredValue, 1);
            }
          }



        } else if (filedDetails.type === "custom") {
          if (secFromValues.length > 0) {
            const fieldObject = secFromValues.find(secFromValues => secFromValues.valueName === filedDetails.mapTo);
            if (fieldObject != undefined) {
              fieldObject['valueName'] = filedDetails.mapTo;
              fieldObject['columnFromFile'] = filedDetails.mapFrom;
              fieldObject['startsAt'] = filedDetails.range.startIndex;
              fieldObject['length'] = filedDetails.range.columnLength;
              fieldObject['type'] = filedDetails.type;
              secMappingArray.push(fieldObject)
              if (filedDetails.mapFrom != '') {
                dropDownObj["id"] = index;
                dropDownObj["name"] = colName.concat(filedDetails.mapFrom);
                dropDownObj["value"] = filedDetails.mapFrom;


              }
              if (filedDetails.mapTo === 'rowindicator') {

                setFieldValue("rowIndicator2", filedDetails.value || '')
              }
              const secfilteredValue = secFromValues.findIndex(secFromValues => secFromValues.valueName === filedDetails.mapTo);
              secDropDownValues.push(dropDownObj)

              secFromValues.splice(secfilteredValue, 1);
            }

          }



        } else if (filedDetails.type === "clearance") {
          if (secFromValues.length > 0) {
            const fieldObject = secFromValues.find(secFromValues => secFromValues.valueName === filedDetails.mapTo);
            if (fieldObject != undefined) {
              fieldObject['valueName'] = filedDetails.mapTo;
              fieldObject['columnFromFile'] = filedDetails.mapFrom;
              fieldObject['startsAt'] = filedDetails.range.startIndex;
              fieldObject['length'] = filedDetails.range.columnLength;
              fieldObject['type'] = filedDetails.type;
              secMappingArray.push(fieldObject)
              if (filedDetails.mapFrom != '') {
                dropDownObj["id"] = index;
                dropDownObj["name"] = colName.concat(filedDetails.mapFrom);
                dropDownObj["value"] = filedDetails.mapFrom;


              }
              if (filedDetails.mapTo === 'rowindicator') {

                setFieldValue("rowIndicator2", filedDetails.value || '')
              }
              const secfilteredValue = secFromValues.findIndex(secFromValues => secFromValues.valueName === filedDetails.mapTo);
              secDropDownValues.push(dropDownObj)

              secFromValues.splice(secfilteredValue, 1);
            }

          }

        }  else if (filedDetails.type === "collections") {
          if (secFromValues.length > 0) {
            const fieldObject = secFromValues.find(secFromValues => secFromValues.valueName === filedDetails.mapTo);
            if (fieldObject != undefined) {
              // fieldObject['id'] = index;
              fieldObject['valueName'] = filedDetails.mapTo;
              fieldObject['columnFromFile'] = filedDetails.mapFrom;
              fieldObject['startsAt'] = filedDetails.range.startIndex;
              fieldObject['length'] = filedDetails.range.columnLength;
              fieldObject['type'] = filedDetails.type;
              // fieldObject['rowIndicator'] = filedDetails.value;
              secMappingArray.push(fieldObject)
              if (filedDetails.mapTo === 'rowindicator') {

                setFieldValue("rowIndicator2", filedDetails.value || '')
              }
              if (filedDetails.mapFrom != '') {
                dropDownObj["id"] = index;
                dropDownObj["name"] = colName.concat(filedDetails.mapFrom);
                dropDownObj["value"] = filedDetails.mapFrom;


              }
              const secfilteredValue = secFromValues.findIndex(secFromValues => secFromValues.valueName === filedDetails.mapTo);
              secDropDownValues.push(dropDownObj)

              secFromValues.splice(secfilteredValue, 1);
            }

          }



        }
        setFirstDragToValues(firstMappingArray)
        setSecDragToValues(secMappingArray)


      })

      setDropDownFirstDragFromValues(removeDuplicates(firstDropDownValues, item => item.name))
      setDropDownSecDragFromValues(removeDuplicates(secDropDownValues, item => item.name))



      setFirstDragFromValues(sortBasedOnNestedOrNot(firstFromValues, "name"))
      setSecDragFromValues(sortBasedOnNestedOrNot(secFromValues, "name"))
    }



    if ((mappingDetails || {}).schemaType === "orders+customs+collections" || (mappingDetails || {}).schemaType === "orders+customs+parcels") {
      const firstFromValues = [...firstDragFromValues];
      const secFromValues = [...secDragFromValues];
      const thirdFromValues = [...thirdDragFromValues];


      (mappingDetails.fieldsMappingSchema || []).sort(function (a, b) {
        return a.mapFrom - b.mapFrom;
      }).map((filedDetails, index) => {
        let fieldObject = {}
        const dropDownObj = {}
        const colName = "Col"

        if (filedDetails.type === "order") {
          if (firstFromValues.length > 0) {
            fieldObject = firstFromValues.find(firstFromValues => firstFromValues.valueName === filedDetails.mapTo);
            if (fieldObject != undefined) {
              // fieldObject['id'] = index;
              fieldObject['valueName'] = filedDetails.mapTo;
              fieldObject['columnFromFile'] = filedDetails.mapFrom;
              fieldObject['startsAt'] = filedDetails.range.startIndex;
              fieldObject['length'] = filedDetails.range.columnLength;
              fieldObject['type'] = filedDetails.type;
              // fieldObject['rowIndicator'] = filedDetails.value;
              if (filedDetails.mapTo === 'rowindicator') {
                setFieldValue("rowIndicator1", filedDetails.value || '')
              }

              if (filedDetails.mapFrom != '') {
                dropDownObj["id"] = index;
                dropDownObj["name"] = colName.concat(filedDetails.mapFrom);
                dropDownObj["value"] = filedDetails.mapFrom;


              }
              firstMappingArray.push(fieldObject)
              const filteredValue = firstFromValues.findIndex(firstFromValues => firstFromValues.valueName === filedDetails.mapTo);
              firstDropDownValues.push(dropDownObj)

              firstFromValues.splice(filteredValue, 1);
            }

          }


        } else if (filedDetails.type === "custom") {
          if (secFromValues.length > 0) {
            fieldObject = secFromValues.find(secFromValues => secFromValues.valueName === filedDetails.mapTo);

            if (fieldObject != undefined) {
              // fieldObject['id'] = index;
              fieldObject['valueName'] = filedDetails.mapTo;
              fieldObject['columnFromFile'] = filedDetails.mapFrom;
              fieldObject['startsAt'] = filedDetails.range.startIndex;
              fieldObject['length'] = filedDetails.range.columnLength;
              fieldObject['type'] = filedDetails.type;
              //  fieldObject['rowIndicator'] = filedDetails.value;
              if (filedDetails.mapTo === 'rowindicator') {

                setFieldValue("rowIndicator2", filedDetails.value || '')
              }
              if (filedDetails.mapFrom != '') {
                dropDownObj["id"] = index;
                dropDownObj["name"] = colName.concat(filedDetails.mapFrom);
                dropDownObj["value"] = filedDetails.mapFrom;


              }

              secMappingArray.push(fieldObject)
              const filteredValue = secFromValues.findIndex(secFromValues => secFromValues.valueName === filedDetails.mapTo);
              secDropDownValues.push(dropDownObj)

              secFromValues.splice(filteredValue, 1);
            }

          }


        } else if (filedDetails.type === "collections" || filedDetails.type === "parcel" || filedDetails.type === "clearance") {
          if (thirdFromValues.length > 0) {


            fieldObject = thirdFromValues.find(thirdFromValues => thirdFromValues.valueName === filedDetails.mapTo);
            if (fieldObject != undefined) {
              // fieldObject['id'] = index;
              fieldObject['valueName'] = filedDetails.mapTo;
              fieldObject['columnFromFile'] = filedDetails.mapFrom;
              fieldObject['startsAt'] = filedDetails.range.startIndex;
              fieldObject['length'] = filedDetails.range.columnLength;
              fieldObject['type'] = filedDetails.type;
              // fieldObject['rowIndicator'] = filedDetails.value;
              if (filedDetails.mapTo === 'rowindicator') {

                setFieldValue("rowIndicator3", filedDetails.value || '')
              }
              if (filedDetails.mapFrom != '') {
                dropDownObj["id"] = index;
                dropDownObj["name"] = colName.concat(filedDetails.mapFrom);
                dropDownObj["value"] = filedDetails.mapFrom;


              }
              thirdMappingArray.push(fieldObject)
              const filteredValue = thirdFromValues.findIndex(thirdFromValues => thirdFromValues.valueName === filedDetails.mapTo);
              thirdDropDownValues.push(dropDownObj)

              thirdFromValues.splice(filteredValue, 1);
            }


          }

        }

        setFirstDragToValues(firstMappingArray)
        setSecDragToValues(secMappingArray)
        setThirdDragToValues(thirdMappingArray)


      })

      setDropDownFirstDragFromValues(removeDuplicates(firstDropDownValues, item => item.name))
      setDropDownSecDragFromValues(removeDuplicates(secDropDownValues, item => item.name))
      setDropDownThirdDragFromValues(removeDuplicates(thirdDropDownValues, item => item.name))

      setFirstDragFromValues(sortBasedOnNestedOrNot(firstFromValues, "name"))
      setSecDragFromValues(sortBasedOnNestedOrNot(secFromValues, "name"))
      setThirdDragFromValues(sortBasedOnNestedOrNot(thirdFromValues, "name"))
    }




  }, [mappingDetails])

  useEffect(() => {

    let feildArray = [...dragToValues, ...firstDragToValues, ...secDragToValues, ...thirdDragToValues];
    props.getFieldSchemaDetails(feildArray);
  }, [dragToValues, firstDragToValues, secDragToValues, thirdDragToValues])

  useEffect(() => {
    const { rowIndicator3 } = formikValues;
    setCurrentRowIndicatorValue && setCurrentRowIndicatorValue(rowIndicator3?.trim() || "")
  }, [formikValues.rowIndicator3])

  useEffect(() => {
    const { rowIndicator2 } = formikValues;
    setCurrentRowIndicatorValue && setCurrentRowIndicatorValue(rowIndicator2?.trim() || "")
  }, [formikValues.rowIndicator2])

  useEffect(() => {
    const { rowIndicator1 } = formikValues;
    setCurrentRowIndicatorValue && setCurrentRowIndicatorValue(rowIndicator1?.trim() || "")
  }, [formikValues.rowIndicator1])

  const removeFromfields = (selecteddata) => {

    const toValues = [...dragToValues];
    const fromValues = [...dragFromValues];

    const itemsToBeDeleted = selecteddata.map((address) => {
      if (formikValues.importType === 'delimited') {
        address['columnFromFile'] = '';
        if (address.name === 'Row Indicator') {
          address['rowIndicator'] = '';
        }
      }

      address['startsAt'] = '';
      address['length'] = '';
      address['type'] = typeOfSchema;


      toValues.push(address);
      setDragToValues(toValues);
      const filteredValue = fromValues.indexOf(address);
      fromValues.splice(filteredValue, 1);



    });
    setDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));
  };


  const removeFromImportFiles = (selecteddata) => {
    const fromValues = [...dragFromValues];

    const toValues = [...dragToValues];
    const itemsToBeDeleted = selecteddata.map((address) => {
      fromValues.push(address);
      setDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));

      const filteredValue = toValues.indexOf(address);

      toValues.splice(filteredValue, 1);
    });
    setDragToValues(toValues);
  };

  const removeFirstFromfields = (selecteddata) => {
    const toValues = [...firstDragToValues];
    const fromValues = [...firstDragFromValues];

    const itemsToBeDeleted = selecteddata.map((address) => {
      if (formikValues.importType === 'delimited') {
        address['columnFromFile'] = '';
        if (address.name === 'Row Indicator') {
          address['rowIndicator'] = '';
        }
      } else {
        address['startsAt'] = '';
        address['length'] = '';
      }
      address['type'] = 'order';


      toValues.push(address);
      setFirstDragToValues(toValues);
      const filteredValue = fromValues.indexOf(address);
      fromValues.splice(filteredValue, 1);

    });
    setFirstDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));
  };

  const removeFirstFromImportFiles = (selecteddata) => {
    const fromValues = [...firstDragFromValues];

    const toValues = [...firstDragToValues];
    const itemsToBeDeleted = selecteddata.map((address) => {
      fromValues.push(address);
      setFirstDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));

      const filteredValue = toValues.indexOf(address);

      toValues.splice(filteredValue, 1);
    });
    setFirstDragToValues(toValues);
  };

  const removeSecFromfields = (selecteddata) => {
    const toValues = [...secDragToValues];
    const fromValues = [...secDragFromValues];

    const itemsToBeDeleted = selecteddata.map((address) => {
      if (formikValues.importType === 'delimited') {
        address['columnFromFile'] = '';
        if (address.name === 'Row Indicator') {
          address['rowIndicator'] = '';
        }
      } else {
        address['startsAt'] = '';
        address['length'] = '';
      }

      if ((formikValues.schemaType || {}).value === 'orders+collections') {

        address['type'] = 'collections';
      } else if((formikValues.schemaType || {}).value === 'orders+clearance'){
        address['type'] = 'clearance';
      }else{
        address['type'] = 'custom';
      }

      toValues.push(address);
      setSecDragToValues(toValues);
      const filteredValue = fromValues.indexOf(address);
      fromValues.splice(filteredValue, 1);

    });
    setSecDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));
  };

  const removeSecFromImportFiles = (selecteddata) => {
    const fromValues = [...secDragFromValues];

    const toValues = [...secDragToValues];
    const itemsToBeDeleted = selecteddata.map((address) => {
      fromValues.push(address);
      setSecDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));

      const filteredValue = toValues.indexOf(address);

      toValues.splice(filteredValue, 1);
    });
    setSecDragToValues(toValues);
  };

  const removeThirdFromfields = (selecteddata) => {
    const toValues = [...thirdDragToValues];
    const fromValues = [...thirdDragFromValues];

    const itemsToBeDeleted = selecteddata.map((address) => {
      if (formikValues.importType === 'delimited') {
        address['columnFromFile'] = '';
        if (address.name === 'Row Indicator') {
          address['rowIndicator'] = '';
        }
      } else {
        address['startsAt'] = '';
        address['length'] = '';
      }

      address['type'] = typeOfSchema === "orders+customs+parcels" ? "parcel" : 'collections';


      toValues.push(address);
      setThirdDragToValues(toValues);
      const filteredValue = fromValues.indexOf(address);
      fromValues.splice(filteredValue, 1);

    });
    setThirdDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));
  };

  const removeThirdFromImportFiles = (selecteddata) => {
    const fromValues = [...thirdDragFromValues];

    const toValues = [...thirdDragToValues];
    const itemsToBeDeleted = selecteddata.map((address) => {
      fromValues.push(address);
      setThirdDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));

      const filteredValue = toValues.indexOf(address);

      toValues.splice(filteredValue, 1);
    });
    setThirdDragToValues(toValues);
  };

  const leftSideColumns = [
    {
      title: 'Available fields', field: 'name', render: (row) => <div>
        {row.name} {row.isRequiredField ? <span style={{ color: "red" }}>**</span> : ''}
      </div>
    }
  ];
  const rightSideColumns = [
    {
      title: 'Selected fields in import file', field: 'name', render: (row) => {
        return <div>{row.name}</div>
      }
    },
    {
      title: 'Columns from the file',
      field: 'columnFromFile',
      render: (row) => {
        let dropDownArray = []

        if (dropdownValues.length === 0) {
          if (!formikValues.isheaderIncluded && formikValues.isheaderIncluded != undefined) {
            dropDownArray = dragFromDropDownValues.sort(function (a, b) {
              return a.value - b.value;
            })
          } else {
            dropDownArray = dragFromDropDownValues
          }

        } else {
          if (formikValues.isheaderIncluded) {
            (dropdownValues || []).map((item, index) => dropdownValues.length === index + 1 ?
              ((typeof item.value === 'string' || item.value instanceof String) ? item.value = item.value.replace(/\r/g, "") : item)
              : item)
          }

          dropDownArray = dropdownValues

        }


        const handleChange = (event) => {

          const tableData = [...dragToValues];
          tableData[row.tableData.id].columnFromFile = event.target.value;
          setCurrentValue(event.target.value);
          setDragToValues(tableData);
        };

        return (
          <>
            <select id={row.name} disabled={isDefaultSchema} value={row.columnFromFile} onChange={handleChange}>
              {
                dropDownArray?.map((item, i) => <option key={i} value={item.value}>{item.name}</option>)
              }
            </select>
            {dropDownArray.length === 0 ? <span className="errMessage">Please check the example file</span> : requiredField && row.isRequiredField && row.columnFromFile === '' ? <span className="errMessage">	Column selection is required</span> : ''}




          </>
        );
      },
    },
    //     {
    //       title: 'Row indicator value',
    //       field: 'rowIndicator',
    //       render: (row) => {
    //         const handleChange = (event) => {
    //           const tableData = [...dragToValues];
    //           tableData[row.tableData.id].rowIndicator = event.target.value;
    //           setDragToValues(tableData);
    //         };

    //         return (
    //           <>
    //             {row.rowIndicator != undefined
    // ? (
    //               <form>
    //                 <input type="text" value={row.rowIndicator} onChange={handleChange} />
    //                 {requiredField && row.isRequiredField && row.rowIndicator === '' ? <span style={{color:"red", padding:'5px'}}>Row indicator value is required</span> : ''}
    //                           </form>
    //             )
    // : (
    //               ''
    //             )}
    //           </>
    //         );
    //       },
    //     },
  ];

  const rightSideColumnsFixedLength = [
    { title: 'Selected fields in import file', field: 'name', render: (row) => <div>{row.name}</div> },
    {
      title: 'Starts at',
      field: 'startsAt',
      render: (row) => {
        const handleChange = (event) => {
          const tableData = [...dragToValues];
          tableData[row.tableData.id].startsAt = event.target.value;
          setCurrentValueStartLength([tableData[row.tableData.id].startsAt, tableData[row.tableData.id].length])
          setDragToValues(tableData);
        };


        return (
          <>
            <form>
              <input type="text" value={row.startsAt} onChange={handleChange} />
              {requiredField && formikValues.importType === 'fixedlength' ?
                (row.isRequiredField ?
                  row.startsAt === '' ? <span className="errMessage">Starts at value is required</span> : (row.startsAt != '' && isNaN(row.startsAt)) || (row.startsAt != '' && row.startsAt < 0) ? <span className="errMessage">Should be a valid number</span> : <span className="errMessage"></span>
                  : (row.startsAt != '' && isNaN(row.startsAt)) || (row.startsAt != '' && row.startsAt < 0) ? <span className="errMessage">Should be a valid number</span> : '')
                : ""}
            </form>
          </>
        );
      },
    },
    {
      title: 'Length',
      field: 'length',
      render: (row) => {
        const handleChange = (event) => {
          const tableData = [...dragToValues];
          tableData[row.tableData.id].length = event.target.value;
          setCurrentValueStartLength([tableData[row.tableData.id].startsAt, tableData[row.tableData.id].length])
          setDragToValues(tableData);
        };

        return (
          <>
            <form>
              <input type="text" value={row.length} onChange={handleChange} />
              {requiredField && formikValues.importType === 'fixedlength' ?
                (row.isRequiredField ?
                  row.length === '' ? <span className="errMessage">Length is required</span> : (row.length != '' && isNaN(row.length)) || (row.length != '' && row.length < 0) ? <span className="errMessage">Length should be a number</span> : ''
                  : (row.length != '' && isNaN(row.length)) || (row.length != '' && row.length < 0) ? <span className="errMessage">Length should be a number</span> : '')
                : ""}


            </form>
          </>
        );
      },
    },
  ];

  const firstRightSideColumns = [
    { title: 'Selected fields in import file', field: 'name', render: (row) => <div>{row.name}</div> },
    {
      title: 'Columns from the file',
      field: 'columnFromFile',
      render: (row) => {
        let dropDownArray = []

        if (dropdownValues.length === 0) {
          dropDownArray = dropDownFirstDragFromValues.sort(function (a, b) {
            return a.value - b.value;
          })
        } else {
          dropDownArray = dropdownValues

        }

        const handleChange = (event) => {

          const tableData = [...firstDragToValues];
          tableData[row.tableData.id].columnFromFile = event.target.value;
          setCurrentValue(event.target.value);
          setFirstDragToValues(tableData);
          row.name.toLowerCase() === "row indicator" && setCurrentRowIndicatorPlacement(row)
        };




        return (
          <>

            <select id={row.name} value={row.columnFromFile} onChange={handleChange}>
              {
                dropDownArray.map((item, i) => <option key={i} value={item.value}>{item.name}</option>)

              }

            </select>
            {dropDownArray.length === 0 ? <span className="errMessage">Please check the example file</span> : requiredField && row.isRequiredField && row.columnFromFile === '' ? <span className="errMessage">	Column selection is required</span> : ''}

          </>
        );
      },
    },
    //     {
    //       title: 'Row indicator value',
    //       field: 'rowIndicator',
    //       render: (row) => {
    //         const handleChange = (event) => {

    //           const tableData = [...firstDragToValues];
    //           tableData[row.tableData.id].rowIndicator = event.target.value;
    //           setFirstDragToValues(tableData);
    //         };

    //         return (
    //           <>
    //             {row.rowIndicator != undefined &&  row.rowIndicator != ''
    // ? (
    //               <form>
    //                 <input type="text" value={row.rowIndicator} onChange={handleChange} />
    //                 {requiredField && row.isRequiredField && row.rowIndicator === '' ? <span style={{color:"red", padding:'5px'}}>Row indicator value is required</span> : ''}

    //               </form>
    //             )
    // : (
    //               ''
    //             )}
    //           </>
    //         );
    //       },
    //     },
  ];
  const firstRightSideColumnsFixedLength = [
    { title: 'Selected fields in import file', field: 'name', render: (row) => <div>{row.name}</div> },
    {
      title: 'Starts at',
      field: 'startsAt',
      render: (row) => {

        const handleChange = (event) => {

          const tableData = [...firstDragToValues];
          tableData[row.tableData.id].startsAt = event.target.value;
          setCurrentValueStartLength([tableData[row.tableData.id].startsAt, tableData[row.tableData.id].length])
          setFirstDragToValues(tableData);
          row.name.toLowerCase() === "row indicator" && setCurrentRowIndicatorPlacement(row)
        };

        return (
          <>
            <form>
              <input type="text" value={row.startsAt} onChange={handleChange} />
              {requiredField && formikValues.importType === 'fixedlength' ?
                (row.isRequiredField ?
                  row.startsAt === '' ? <span className="errMessage">Starts at value is required</span> : (row.startsAt != '' && isNaN(row.startsAt)) || (row.startsAt != '' && row.startsAt < 0) ? <span className="errMessage">Should be a valid number</span> : ''
                  : (row.startsAt != '' && isNaN(row.startsAt)) || (row.startsAt != '' && row.startsAt < 0) ? <span className="errMessage">Should be a valid number</span> : '')
                : ""}
            </form>
          </>
        );
      },
    },
    {
      title: 'Length',
      field: 'length',
      render: (row) => {
        const handleChange = (event) => {
          const tableData = [...firstDragToValues];
          tableData[row.tableData.id].length = event.target.value;
          setCurrentValueStartLength([tableData[row.tableData.id].startsAt, tableData[row.tableData.id].length])
          setFirstDragToValues(tableData);
        };

        return (
          <>
            <form>
              <input type="text" value={row.length} onChange={handleChange} />
              {requiredField && formikValues.importType === 'fixedlength' ?
                (row.isRequiredField ?
                  row.length === '' ? <span className="errMessage"> Length is required</span> : (row.length != '' && isNaN(row.length)) || (row.length != '' && row.length < 0) ? <span className="errMessage">Length should be a number</span> : ''
                  : (row.length != '' && isNaN(row.length)) || (row.length != '' && row.length < 0) ? <span className="errMessage">Length should be a number</span> : '')
                : ""}
            </form>
          </>
        );
      },
    },
  ];
  const secRightSideColumns = [
    { title: 'Selected fields in import file', field: 'name', render: (row) => <div>{row.name}</div> },
    {
      title: 'Columns from the file',
      field: 'columnFromFile',
      render: (row) => {
        let dropDownArray = []

        if (dropdownValues.length === 0) {
          dropDownArray = dropDownSecDragFromValues.sort(function (a, b) {
            return a.value - b.value;
          })
        } else {
          dropDownArray = dropdownValues

        }
        const handleChange = (event) => {

          const tableData = [...secDragToValues];
          tableData[row.tableData.id].columnFromFile = event.target.value;
          setCurrentValue(event.target.value);
          setSecDragToValues(tableData);
          row.name.toLowerCase() === "row indicator" && setCurrentRowIndicatorPlacement(row)

        };



        return (
          <>

            <select id={row.name} value={row.columnFromFile} onChange={handleChange}>
              {
                dropDownArray.map((item, i) => <option key={i} value={item.value}>{item.name}</option>)

              }
            </select>
            {dropDownArray.length === 0 ? <span className="errMessage">Please check the example file</span> : requiredField && row.isRequiredField && row.columnFromFile === '' ? <span className="errMessage">	Column selection is required</span> : ''}

          </>
        );
      },
    },
    //     {
    //       title: 'Row indicator value',
    //       field: 'rowIndicator',
    //       render: (row) => {
    //         const handleChange = (event) => {

    //           const tableData = [...secDragToValues];
    //           tableData[row.tableData.id].rowIndicator = event.target.value;
    //           setSecDragToValues(tableData);
    //         };

    //         return (
    //           <>
    //             {row.rowIndicator != undefined &&  row.rowIndicator != ''
    // ? (
    //               <form>
    //                 <input type="text" value={row.rowIndicator} onChange={handleChange} />
    //                 {requiredField && row.isRequiredField && row.rowIndicator === '' ? <span style={{color:"red", padding:'5px'}}>Row indicator value is required</span> : ''}

    //               </form>
    //             )
    // : (
    //               ''
    //             )}
    //           </>
    //         );
    //       },
    //     },
  ];
  const secRightSideColumnsFixedLength = [
    { title: 'Selected fields in import file', field: 'name', render: (row) => <div>{row.name}</div> },
    {
      title: 'Starts at',
      field: 'startsAt',
      render: (row) => {
        const handleChange = (event) => {

          const tableData = [...secDragToValues];
          tableData[row.tableData.id].startsAt = event.target.value;
          setCurrentValueStartLength([tableData[row.tableData.id].startsAt, tableData[row.tableData.id].length])
          setSecDragToValues(tableData);
          row.name.toLowerCase() === "row indicator" && setCurrentRowIndicatorPlacement(row)

        };

        return (
          <>
            <form>
              <input type="text" value={row.startsAt} onChange={handleChange} />
              {requiredField && formikValues.importType === 'fixedlength' ?
                (row.isRequiredField ?
                  row.startsAt === '' ? <span className="errMessage">Starts at value is required</span> : (row.startsAt != '' && isNaN(row.startsAt)) || (row.startsAt != '' && row.startsAt < 0) ? <span className="errMessage">Should be a valid number</span> : ''
                  : (row.startsAt != '' && isNaN(row.startsAt)) || (row.startsAt != '' && row.startsAt < 0) ? <span className="errMessage">Should be a valid number</span> : '')
                : ""}
            </form>
          </>
        );
      },
    },
    {
      title: 'Length',
      field: 'length',
      render: (row) => {
        const handleChange = (event) => {
          const tableData = [...secDragToValues];
          tableData[row.tableData.id].length = event.target.value;
          setCurrentValueStartLength([tableData[row.tableData.id].startsAt, tableData[row.tableData.id].length])
          setSecDragToValues(tableData);
        };

        return (
          <>
            <form>
              <input type="text" value={row.length} onChange={handleChange} />
              {requiredField && formikValues.importType === 'fixedlength' ?
                (row.isRequiredField ?
                  row.length === '' ? <span className="errMessage"> Length is required</span> : (row.length != '' && isNaN(row.length)) || (row.length != '' && row.length < 0) ? <span className="errMessage">Length should be a number</span> : ''
                  : (row.length != '' && isNaN(row.length)) || (row.length != '' && row.length < 0) ? <span className="errMessage">Length should be a number</span> : '')
                : ""}
            </form>
          </>
        );
      },
    },
  ];
  const thirdRightSideColumns = [
    { title: 'Selected fields in import file', field: 'name', render: (row) => <div>{row.name}</div> },
    {
      title: 'Columns from the file',
      field: 'columnFromFile',
      render: (row) => {
        let dropDownArray = []

        if (dropdownValues.length === 0) {
          dropDownArray = dropDownThirdDragFromValues.sort(function (a, b) {
            return a.value - b.value;
          })
        } else {
          dropDownArray = dropdownValues

        }
        const handleChange = (event) => {

          const tableData = [...thirdDragToValues];
          tableData[row.tableData.id].columnFromFile = event.target.value;
          setCurrentValue(event.target.value);
          setThirdDragToValues(tableData);
          row.name.toLowerCase() === "row indicator" && setCurrentRowIndicatorPlacement(row)

        };



        return (
          <>

            <select id={row.name} value={row.columnFromFile} onChange={handleChange}>
              {
                dropDownArray.map((item, i) => <option key={i} value={item.value}>{item.name}</option>)

              }
            </select>
            {dropDownArray.length === 0 ? <span className="errMessage">Please check the example file</span> : requiredField && row.isRequiredField && row.columnFromFile === '' ? <span className="errMessage">	Column selection is required</span> : ''}

          </>
        );
      },
    },
    //     {
    //       title: 'Row indicator value',
    //       field: 'rowIndicator',
    //       render: (row) => {
    //         const handleChange = (event) => {
    //           const tableData = [...thirdDragToValues];
    //           tableData[row.tableData.id].rowIndicator = event.target.value;
    //           setThirdDragToValues(tableData);
    //         };

    //         return (
    //           <>
    //             {row.rowIndicator != undefined &&  row.rowIndicator != ''
    // ? (
    //               <form>
    //                 <input type="text" value={row.rowIndicator} onChange={handleChange} />
    //                 {requiredField && row.isRequiredField && row.rowIndicator === '' ? <span style={{color:"red", padding:'5px'}}>Row indicator value is required</span> : ''}

    //               </form>
    //             )
    // : (
    //               ''
    //             )}
    //           </>
    //         );
    //       },
    //     },
  ];
  const thirdRightSideColumnsFixedLength = [
    { title: 'Selected fields in import file', field: 'name', render: (row) => <div>{row.name}</div> },
    {
      title: 'Starts at',
      field: 'startsAt',
      render: (row) => {
        const handleChange = (event) => {

          const tableData = [...thirdDragToValues];
          tableData[row.tableData.id].startsAt = event.target.value;
          setCurrentValueStartLength([tableData[row.tableData.id].startsAt, tableData[row.tableData.id].length])
          setThirdDragToValues(tableData);
          row.name.toLowerCase() === "row indicator" && setCurrentRowIndicatorPlacement(row)
        };

        return (
          <>
            <form>
              <input type="text" value={row.startsAt} onChange={handleChange} />
              {requiredField && formikValues.importType === 'fixedlength' ?
                (row.isRequiredField ?
                  row.startsAt === '' ? <span className="errMessage">Starts at value is required</span> : (row.startsAt != '' && isNaN(row.startsAt)) || (row.startsAt != '' && row.startsAt < 0) ? <span className="errMessage">Should be a valid number</span> : ''
                  : (row.startsAt != '' && isNaN(row.startsAt)) || (row.startsAt != '' && row.startsAt < 0) ? <span className="errMessage">Should be a valid number</span> : '')
                : ""}
            </form>
          </>
        );
      },
    },
    {
      title: 'Length',
      field: 'length',
      render: (row) => {
        const handleChange = (event) => {
          const tableData = [...thirdDragToValues];
          tableData[row.tableData.id].length = event.target.value;
          setCurrentValueStartLength([tableData[row.tableData.id].startsAt, tableData[row.tableData.id].length])
          setThirdDragToValues(tableData);
        };

        return (
          <>
            <form>
              <input type="text" value={row.length} onChange={handleChange} />
              {requiredField && formikValues.importType === 'fixedlength' ?
                (row.isRequiredField ?
                  row.length === '' ? <span className="errMessage"> Length is required</span> : (row.length != '' && isNaN(row.length)) || (row.length != '' && row.length < 0) ? <span className="errMessage">Length should be a number</span> : ''
                  : (row.length != '' && isNaN(row.length)) || (row.length != '' && row.length < 0) ? <span className="errMessage">Length should be a number</span> : '')
                : ""}
            </form>
          </>
        );
      },
    },
  ];
  return (
    <div>
      {multiTab
        ? (
          <>
            {' '}
            <AppBar position="static" className="Schema-AppBar">
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={5} lg={5}>
                  <Tabs value={currentTab} onChange={handleChange}>
                    <Tab label={firstTab} />
                    <Tab label={secTab} />
                    <Tab label={thirdTab} disabled={thirdTab === ''} />
                  </Tabs>
                </Grid>
              </Grid>
            </AppBar>
            <TabPanel value={currentTab} index={0}>
              <Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} spacing={3}>
                  <Grid item xs={3} sm={3} md={3} lg={4}>
                    <FormRenderer {...formField.rowIndicator1.props} isDisabled={isDefaultSchema} fullWidth />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} spacing={3}>
                  <div>
                    <b>Note: </b><span>Please enter the row indicator to create the mapping(Case sensitive) </span>
                  </div>
                </Grid>
              </Grid>
              <SchemaMaterialTable
                dragToValues={firstDragToValues}
                dragFromValues={firstDragFromValues}
                leftSideColumns={leftSideColumns}
                rightSideColumns={formikValues.importType === 'delimited' ? firstRightSideColumns : firstRightSideColumnsFixedLength}
                removeFromfields={removeFirstFromfields}
                removeFromImportFiles={removeFirstFromImportFiles}
                setCurrentValueStartLength={setCurrentValueStartLength}
                setCurrentValue={setCurrentValue}
                isDefaultSchema={isDefaultSchema}

              />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} spacing={3}>
                  <Grid item xs={12} sm={12} md={3} lg={4}>
                    <FormRenderer {...formField.rowIndicator2.props} isDisabled={isDefaultSchema} fullWidth />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} spacing={3}>
                  <div>
                    <b>Note: </b><span>Please enter the row indicator to create the mapping(Case sensitive) </span>
                  </div>
                </Grid>
              </Grid>
              <SchemaMaterialTable
                dragToValues={secDragToValues}
                dragFromValues={secDragFromValues}
                leftSideColumns={leftSideColumns}
                rightSideColumns={formikValues.importType === 'delimited' ? secRightSideColumns : secRightSideColumnsFixedLength}
                setCurrentValueStartLength={setCurrentValueStartLength}
                setCurrentValue={setCurrentValue}
                removeFromfields={removeSecFromfields}
                removeFromImportFiles={removeSecFromImportFiles}
                isDefaultSchema={isDefaultSchema}

              />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} spacing={3}>
                  <Grid item xs={12} sm={12} md={3} lg={4}>
                    <FormRenderer {...formField.rowIndicator3.props} isDisabled={isDefaultSchema} fullWidth />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} spacing={3}>
                  <div>
                    <b>Note: </b><span>Please enter the row indicator to create the mapping(Case sensitive) </span>
                  </div>
                </Grid>
              </Grid>
              <SchemaMaterialTable
                dragToValues={thirdDragToValues}
                dragFromValues={thirdDragFromValues}
                leftSideColumns={leftSideColumns}
                rightSideColumns={formikValues.importType === 'delimited' ? thirdRightSideColumns : thirdRightSideColumnsFixedLength}
                removeFromfields={removeThirdFromfields}
                removeFromImportFiles={removeThirdFromImportFiles}
                setCurrentValueStartLength={setCurrentValueStartLength}
                setCurrentValue={setCurrentValue}
                isDefaultSchema={isDefaultSchema}

              />
            </TabPanel>
          </>
        )
        : (
          <>
            <AppBar position="static" className="Schema-AppBar">
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={5} lg={5}>
                  <Tabs value={0}>
                    <Tab label={firstTab} />
                  </Tabs>
                </Grid>
              </Grid>
            </AppBar>
            <TabPanel value={0} index={0}>
              <SchemaMaterialTable
                dragToValues={dragToValues}
                dragFromValues={dragFromValues}
                leftSideColumns={leftSideColumns}
                rightSideColumns={formikValues.importType === 'delimited' ? rightSideColumns : rightSideColumnsFixedLength}
                removeFromfields={removeFromfields}
                removeFromImportFiles={removeFromImportFiles}
                setCurrentValueStartLength={setCurrentValueStartLength}
                setCurrentValue={setCurrentValue}
                isDefaultSchema={isDefaultSchema}
              />
            </TabPanel>
          </>
        )}
    </div>
  );
};

export default SchemaMappingGrid;
