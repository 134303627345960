import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  IconButton,
  SvgIcon,
} from '@material-ui/core';

import { ReactComponent as DeleteIcon } from '../../../../../assets/images/DeleteIcon.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/images/EditIcon.svg';
import { ReactComponent as AddIcon } from '../../../../../assets/images/AddIcon.svg';
import CustomDialog from './../../../../../framework/dialog/customDialog';
import AddClearanceItemDefault from './addClearanceItemDefault';

const ClearanceItemDetails = (props) => {
  const {
    formField: { clearanceItemData },
    formikValues,
    errors,
    setFieldValue,
    isAddNewAddress,
    isOrderDefaults,
    shipmentMovementType
  } = props;

  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [footerValues, setFooterValues] = React.useState({
    subTotal: 0,
    totalValue: 0,
  });
  const [spanIsActive, setspanIsActive] = React.useState(false);
  const [commodityWeight, setcommodityWeight] = React.useState(0);
  const [checkItemnumber,setItemNumber] = useState(true);

  var commodityDetails_weight = 0;
  const openAddCommodityDetails = () => {
    setModalData({
      operationType: 'addCommodityDetails',
      totalWeight: formikValues.weight,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addNewCommodityDetails = (arrayHelpers, values) => {
    arrayHelpers.push(values);
    setOpen(false);
  };

  const updateCommodityDetails = (arrayHelpers, index, values) => {
    arrayHelpers.replace(index, values);
    setOpen(false);
  };

  const openUpdateCommodityDetails = (index, rowDetails) => {
    setModalData({
      index,
      rowDetails,
      operationType: 'updateCommodityDetails',
      totalWeight: formikValues.weight,
    });
    setOpen(true);
  };
  
  return (
    <div className="wd-100">
      <FieldArray
        name={clearanceItemData.props.name}
        render={(arrayHelpers) => {
          const commodityItems = formikValues.clearanceItemData;
          return (
            <Grid>
              <Grid container direction="row" justify="space-between">
                <Grid item style={{ marginTop: '1%' }}>
                  <strong>Item details</strong>
                </Grid>
                <Grid item>
                  <strong>
                    <IconButton disabled={(commodityItems || []).length > 19} onClick={openAddCommodityDetails}>
                      <SvgIcon component={AddIcon} viewBox="5 5 30 30" />
                    </IconButton>
                    Add new
                  </strong>
                  <CustomDialog
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                    className="commodityDetails-popup"
                    title={
                      modalData.operationType === 'updateCommodityDetails'
                        ? 'Update item description'
                        : 'Add item description'
                    }
                  >
                    <AddClearanceItemDefault
                      data={modalData}
                      addNewCommodityDetails={(values) => {
                        addNewCommodityDetails(arrayHelpers, values);
                      }}
                      updateCommodityDetails={(index, values) => updateCommodityDetails(arrayHelpers, index, values)}
                    />
                  </CustomDialog>
                </Grid>
              </Grid>
              <TableContainer component={Paper} className="tableContainer Add_shipment_tableContainer">
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <strong>Description of goods</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Commodity code</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Unit Quantity</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Unit Weight</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Unit Value</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Country of origin</strong>
                      </TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commodityItems?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{row?.fullDescriptionOfGoods}</TableCell>
                        <TableCell component="th" scope="row">
                          {row?.commodityCode}
                        </TableCell>
                        <TableCell align="left">{row.quantity}</TableCell>
                          <TableCell align="left">{row?.unitWeight}</TableCell>
                          <TableCell align="left">{row?.unitValue}</TableCell>
                          <TableCell align="left">{row?.countryOfOrigin?.name}</TableCell>
                        <TableCell align="left">
                          <IconButton onClick={() => openUpdateCommodityDetails(index, row)}>
                            <SvgIcon component={EditIcon} viewBox="5 5 30 30" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left">
                          <IconButton onClick={() => arrayHelpers.remove(index)}>
                            <SvgIcon component={DeleteIcon} viewBox="5 5 30 30" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          );
        }}
      />
    </div>
  );
};



export default ClearanceItemDetails;
