/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { connect, useSelector } from 'react-redux';
import ServicePoint from '../../../../components/addShipment/Products&Services/ServicePoint';
import { doorStepInstructionOptions as instructions } from '../../../../utils/constants/constants';
import { setDropdownValues } from '../../../../utils/helperFunctions';

const DeliveryOptions = (props) => {
  const {
    formField: {
      doorStepInstructions,
      exchange,
      specialInstructions,
      additionalInstruction,
      deliveryType,
      leaveSafeLocation,
    },
    formikValues,
    setFieldValue,
    isDomestic,
    isCarriageForward,
    isTemplate,
    isAddressBook,
    loadedFrom,
    isOrderDefaults,
    isFromProcessOrder,
    isSelectDefaultDoorstepinst,
    servicePointAddress,
    isAmendShipment
  } = props;

  const [showAdditionalInstruction, setShowAdditionalInstruction] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [doorStepInstructionOptions, setDoorStepInstructionOptions] = useState(instructions);

  const allProducts = useSelector((state) => state.common.allProducts);
  const onAddNewInstruction = () => {
    setShowAdditionalInstruction(true);
  };

  const isDeliveryTypeDoorStep = () => {
    return (props.formikValues || {}).deliveryType === 'deliverToDoorStep';
  };

  const isDeliveryInstructionDoorStepOnly = () => {
    return ((props.formikValues || {}).doorStepInstructions || {}).value === 'deliverToDoorStepOnly';
  };

  const isDeliveryInstructionLeaveSafe = () => {
    return ((props.formikValues || {}).doorStepInstructions || {}).value === 'leaveSafe';
  };

  useEffect(() => {
    setShowAdditionalInstruction(false);
    formikValues.additionalInstruction?.trim?.() &&
      formikValues.additionalInstruction.trim() !== 'House no/name:' &&
      setShowAdditionalInstruction(true);
  }, [formikValues.doorStepInstructions, formikValues.deliveryType, formikValues.additionalInstruction]);

  useEffect(() => {
    let ins = instructions;
    const nonPalletProducts = allProducts.filter((p) => !p.deliveryCapabilities?.pallets);
    if (formikValues.createPalletShipment === 'yes') {
      ins = ins.filter((instruction) => instruction.value === 'deliverToDoorStepOnly');
    }
    else if (nonPalletProducts.length > 0) {
      if (
        !nonPalletProducts.some((product) => {
          return product?.deliveryCapabilities?.neighbourDelivery;
        })
      ) {
        ins = ins.filter((instruction) => instruction.value !== 'deliverToNeighbour');
      }
      if (
        !nonPalletProducts.some((product) => {
          return product?.deliveryCapabilities?.secureLocationDelivery;
        })
      ) {
        ins = ins.filter((instruction) => instruction.value !== 'leaveSafe');
      }
      if (
        !nonPalletProducts.some((product) => {
          return (
            !product?.deliveryCapabilities?.neighbourDelivery && !product?.deliveryCapabilities?.secureLocationDelivery
          );
        })
      ) {
        ins = ins.filter((instruction) => instruction.value !== 'deliverToDoorStepOnly');
      }
    }
    if (JSON.stringify(ins) !== JSON.stringify(instructions)) {
      setDoorStepInstructionOptions(ins);
    } else {
      setDoorStepInstructionOptions(instructions);
    }
  }, [allProducts, formikValues?.createPalletShipment]);

  useEffect(() => {
    if (formikValues.deliveryType === 'deliverToDoorStep' && (loadedFrom?.loadedFrom === '' || !loadedFrom)) {
      if ((formikValues.doorStepInstructions || {}).value === 'deliverToNeighbour') {
        setFieldValue('specialInstructions', 'If out leave with neighbour');
        setFieldValue('additionalInstruction', 'House no/name: ');
      } else if ((formikValues.doorStepInstructions || {}).value === 'leaveSafe') {
        setFieldValue('specialInstructions', '');
        setFieldValue('additionalInstruction', '');
        setFieldValue('leaveSafeLocation', 'Leave safe location');
      } else {
        setFieldValue('additionalInstruction', '');
        setFieldValue('specialInstructions', '');
      }
    } else if (!loadedFrom || loadedFrom?.loadedFrom === '') {
      setFieldValue('additionalInstruction', '');
      setFieldValue('specialInstructions', '');
    }

    if (
      isTemplate === true &&
      formikValues.deliveryType === 'deliverToServicePoint' &&
      formikValues.specialInstructions !== '' && (!loadedFrom || loadedFrom?.loadedFrom === '')
    ) {
      setFieldValue('specialInstructions', '');
      setFieldValue('doorStepInstructions', doorStepInstructionOptions[0]);
    }
  }, [formikValues.deliveryType, formikValues.doorStepInstructions]);

  const [isDeliveryTypeDisabled, setIsDeliveryTypeDisabled] = useState(false);

  useEffect(() => {
    const { weight, noOfItems } = formikValues;
    if (isDomestic && ((weight > 20 && !isAmendShipment) || noOfItems > 1)) {
      setFieldValue('deliveryType', 'deliverToDoorStep');
      setIsDeliveryTypeDisabled(true);
    } else if (!loadedFrom || loadedFrom?.loadedFrom === '') {
      // setFieldValue('deliveryType', 'deliverToDoorStep');
      setIsDeliveryTypeDisabled(false);
    }
  }, [formikValues.destinationCountry, formikValues.weight, formikValues.noOfItems, isDomestic]);

  //Set Delivery type for carriage forward form when weight & parcel change.
  useEffect(() => {
    const { fromWeight, fromParcel } = formikValues;
    if (isDomestic && ((fromWeight > 20 && !isAmendShipment) || fromParcel > 1)) {
      setFieldValue('deliveryType', 'deliverToDoorStep');
      setIsDeliveryTypeDisabled(true);
    }
    else {
      setIsDeliveryTypeDisabled(false);
    }
  }, [formikValues.fromWeight, formikValues.fromParcel, isDomestic]);

  useEffect(() => {
    const { createPalletShipment, weight, noOfItems } = formikValues;
    if ((createPalletShipment === 'yes') || (isDomestic && ((weight > 20 && !isAmendShipment) || noOfItems > 1))) {
      setFieldValue('deliveryType', 'deliverToDoorStep');
      setIsDeliveryTypeDisabled(true);
    }
    else {
      setIsDeliveryTypeDisabled(false);
    }
  }, [props.formikValues?.createPalletShipment, props.formikValues?.weight]);

  useEffect(() => {
    let instructionOptions = doorStepInstructionOptions;
    if (!formikValues.doorStepInstructions) {
      // if (isSelectDefaultDoorstepinst && instructionOptions?.[0].name !== 'Select') {
      //   instructionOptions.unshift({
      //     value: '',
      //     name: 'Select',
      //   })
      // }
    }
    formikValues?.deliveryType !== 'deliverToDoorStep' && setFieldValue('doorStepInstructions', instructionOptions[0]);
  }, [formikValues.deliveryType]);

  useEffect(() => {
    if (doorStepInstructionOptions.length > 0) {
      if (loadedFrom?.loadedFrom === 'fromPreviewSummary') {
        setFieldValue(
          'doorStepInstructions',
          setDropdownValues(
            doorStepInstructionOptions,
            'value',
            loadedFrom?.loadedResponse?.doorStepInstructions?.value,
          ),
        );
      } else if (loadedFrom?.loadedFrom) {
        setFieldValue(
          'doorStepInstructions',
          setDropdownValues(
            doorStepInstructionOptions,
            'value',
            loadedFrom?.loadedResponse?.deliveryOptions?.doorStepInstructions,
          ),
        );
      } else {
        setFieldValue('doorStepInstructions', doorStepInstructionOptions[0]);
      }
    }
  }, [doorStepInstructionOptions]);

  useEffect(() => {
    if (formikValues.exchange === 'yes' && formikValues.doorStepInstructions?.value === 'deliverToDoorStepOnly') {
      setShowInstructions(false);
    } else {
      setShowInstructions(true);
    }
  }, [formikValues.exchange, formikValues.doorStepInstructions]);

  useEffect(() => {
    if (formikValues?.createPalletShipment === "yes") {
      setTimeout(() => {
        setFieldValue("exchange", "no");
        setFieldValue('additionalInstruction', '');
        setShowInstructions(false);
      }, 0);
    }
    else if (!(formikValues.exchange === 'yes' && formikValues.doorStepInstructions?.value === 'deliverToDoorStepOnly')) {
      setShowInstructions(true);
    }
  }, [formikValues?.createPalletShipment]);

  useEffect(() => {
    if (props.updateValidationSchema) {
      specialInstructions.validation.validations = specialInstructions.validation.validations.filter(validation => validation.type === "max");
      if (isAmendShipment || isOrderDefaults || isFromProcessOrder) {
        specialInstructions.validation.validations.push({
          type: 'max',
          params: [60, 'Max 60 characters are allowed'],
        });
        specialInstructions.props.inputProps.maxLength = 60;
      }
      else {
        specialInstructions.validation.validations.push({
          type: 'max',
          params: [30, 'Max 30 characters are allowed'],
        });
        specialInstructions.props.inputProps.maxLength = 30;
      }
      props.updateValidationSchema();
    }
  }, [isAmendShipment, isOrderDefaults, isFromProcessOrder]);

  return (
    <div className="wd-100">
      <Grid className="radio-label" container spacing={2} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12}>
          <FormRenderer
            {...deliveryType.props}
            fullWidth
            data={deliveryTypeOptions}
            isDisabled={isDeliveryTypeDisabled || isAmendShipment}
          />
        </Grid>

        {/* <Grid item xs={10} sm={4}></Grid> */}
      </Grid>

      {isDeliveryTypeDoorStep() && isDomestic ? (
        <Grid container spacing={3} direction="row" alignItems="flex-start" style={{ paddingTop: '0px' }}>
          <Grid item xs={12} sm={4} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '38px' }}>
            <FormRenderer {...doorStepInstructions.props} fullWidth data={doorStepInstructionOptions} />
          </Grid>
          {isDeliveryInstructionDoorStepOnly() && !isCarriageForward && !(formikValues?.createPalletShipment === "yes") ? (
            <Grid className="radio-label" item xs={12} sm={6} md={5} lg={5} style={{ marginLeft: '0px' }}>
              <FormRenderer {...exchange.props} data={exchangeOnDelivery} isDisabled={isAmendShipment} fullWidth />
            </Grid>
          ) : null}
          {isDeliveryInstructionLeaveSafe() && !isCarriageForward ? (
            <Grid item xs={12} sm={6} md={isCarriageForward ? 12 : 6}>
              <FormRenderer {...leaveSafeLocation.props} fullWidth />
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      {!isDeliveryTypeDoorStep() && !isTemplate && !isAddressBook && !isOrderDefaults ? (
        <Grid container spacing={3} direction="row" alignItems="flex-start" style={{ paddingTop: '12px' }}>
          <Grid item xs={12} sm={6} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '37px' }}>
            <ServicePoint isDomestic={true} {...props} />
          </Grid>
          {isCarriageForward ? null : <Grid item xs={12} sm={6} md={6} lg={6}></Grid>}
        </Grid>
      ) : null}

      {isDomestic ? (
        <Grid container spacing={3} direction="row" style={{ paddingTop: '4px' }} alignItems="flex-start">
          {isCarriageForward && isDeliveryTypeDoorStep() && isDeliveryInstructionLeaveSafe() ? (
            <>
              {!isCarriageForward && (
                <Grid item xs={12} sm={12} md={12} style={{ paddingRight: '37px' }}>
                  <FormRenderer {...leaveSafeLocation.props} fullWidth />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} style={{ paddingRight: '37px' }}>
                <FormRenderer {...specialInstructions.props} fullWidth />
              </Grid>
            </>
          ) : (
            <Grid item xs={10} sm={6} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '37px' }}>
              <FormRenderer {...specialInstructions.props} fullWidth />
            </Grid>
          )}
          {isCarriageForward ? (
            <Grid item xs={10} sm={6} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '37px' }}>
              <FormRenderer {...additionalInstruction.props} label={'Additional instructions'} fullWidth />
            </Grid>
          ) : null}
          {!isCarriageForward && !isOrderDefaults ? (
            <React.Fragment>
              {showInstructions && showAdditionalInstruction ? (
                <Grid item xs={10} sm={6} md={isCarriageForward ? 12 : 6} style={{ paddingLeft: '15px' }}>
                  <FormRenderer {...additionalInstruction.props} isDisabled={isAmendShipment || isFromProcessOrder} fullWidth />
                </Grid>
              ) : showInstructions && !isOrderDefaults ? (
                <Grid
                  item
                  xs={10}
                  sm={6}
                  md={isCarriageForward ? 12 : 6}
                  style={{ marginTop: '3px', paddingLeft: '10px' }}
                >
                  <Button
                    startIcon={<Add style={{ fontSize: '22px' }} />}
                    onClick={onAddNewInstruction}
                    disabled={isAmendShipment || isFromProcessOrder}
                    style={{ textTransform: 'none' }}
                  >
                    Add another instruction
                  </Button>
                </Grid>
              ) : null}
            </React.Fragment>
          ) : isTemplate && !isOrderDefaults ? (
            <Grid item xs={10} sm={6} md={isCarriageForward ? 12 : 6} style={{ marginTop: '3px', paddingLeft: '10px' }}>
              <Button
                startIcon={<Add style={{ fontSize: '22px' }} />}
                onClick={onAddNewInstruction}
                style={{ textTransform: 'none' }}
              >
                Add another instruction
              </Button>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </div>
  );
};

DeliveryOptions.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
  isCarriageForward: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
  };
};

export default connect(mapStateToProps, null)(DeliveryOptions);

const deliveryTypeOptions = [
  {
    value: 'deliverToDoorStep',
    label: 'Deliver to doorstep',
  },
  {
    value: 'deliverToServicePoint',
    label: 'Deliver to ServicePoint',
  },
];

const exchangeOnDelivery = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];
