/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import PropTypes from 'prop-types';
import InternationalProduct from '../../../../components/addShipment/Products&Services/InternationalProducts';

// import {
//   allProducts,
//   parcelServices,
//   weightToProductMapping,
//   productsWhenItemGreaterThanOne,
// } from '../../../../utils/constants/productService';
import { isValidObject, isValidPostCode } from '../../../../utils/common';
import {
  setDropdownValues,
  getInternationalAccounts,
  getDomesticAccounts,
  basicSort,
  arrayToString,
  getDriverCollectionAccounts,
  getNonReturnAccounts
} from '../../../../utils/helperFunctions';
import { abortOnGoingCallsFor, axiosConfig } from '../../../../api/axios';
import { useDebouncedCallback } from 'use-debounce/lib';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import * as actions from '../../../../store/actions/index';

const ProductAndServiceDetails = (props) => {
  const {
    formField: { product, service },
    setFieldValue,
    formikValues,
    isDomestic,
    isTemplate,
    loadedFrom,
    isOrderDefaults,
    isAddressBook,
    internationalProductList,
    setInternationalProductList
  } = props;

  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [templateResponse, setTemplateResponse] = useState({});
  const allProducts = useSelector((state) => state.common.allProducts);

  const getDorStepInstructionsForParams = () => {
    const DSI = props.formikValues?.doorStepInstructions;
    let instruction = 'doorstepOnly';
    if (DSI?.value === 'deliverToNeighbour') {
      instruction = 'neighbourDelivery';
    } else if (DSI?.value === 'leaveSafe') {
      instruction = 'secureLocationDelivery';
    }
    return instruction;
  };

  useEffect(() => {
    var validationStatus = false;
    const {
      destinationCountry,
      noOfItems,
      weight,
      // length,
      // width,
      // height,
      postalCode,
      addressType,
      deliveryType,
      doorStepInstructions,
      reasonsForExport,
    } = props.formikValues;

    let { displayAccounts, userAccounts } = props;

    if (isAddressBook) {
      displayAccounts = getNonReturnAccounts(getDriverCollectionAccounts(getDomesticAccounts(userAccounts)));
    }
    let url = ServiceEndPoints.getProducts;
    let queryParams = '';
    let availableAccount = [];
    let availableProductType = [];
    if (displayAccounts?.length > 0 && isValidObject(destinationCountry)) {
      if (!isDomestic) {
        availableAccount = getInternationalAccounts(displayAccounts);
      } else {
        availableAccount = getDomesticAccounts(displayAccounts);
      }
      availableAccount.map((item) => {
        availableProductType = [...availableProductType, ...item.allowedProducts];
      });
    }
    availableProductType = [...new Set(availableProductType)];

    let productString = '';

    availableProductType.map((item, index) => {
      if (index !== 0) {
        productString += `&accountTypes[${index}]=${item}`;
      } else {
        productString += `accountTypes[${index}]=${item}`;
      }
    });

    if (
      destinationCountry &&
      selectedAccount &&
      (postalCode || destinationCountry.postcodeOptional) &&
      isValidPostCode(destinationCountry.postcodeRegex, postalCode.toUpperCase().trim()) &&
      isValidObject(weight) &&
      availableProductType.length > 0
    ) {
      validationStatus = true;
    }

    if (validationStatus && !isDomestic && weight > 0) {
      queryParams = `originCountryCode=GB&originPostalCode=${selectedAccount?.sender?.postalCode
        }&destinationCountryCode=${destinationCountry.code2Digit
        }&destinationPostalCode=${postalCode.trim()}&destinationRecipientType=${addressType}&parcels=${noOfItems}&weight=${weight}&${productString}&internationalProductFilter=all&destinationAddressType=${deliveryType === 'deliverToDoorStep' ? 'doorstep' : 'servicePoint'
        }`; 
      queryParams = queryParams + `&currency=${formikValues.customDeclarationCurrency?.code}`;
      if (formikValues.isFullCustomDetails)
        queryParams = queryParams + `&totalValue=${formikValues.subTotal}`;
      else
        queryParams = queryParams + `&totalValue=${formikValues.customDeclarationValue}`;

      // Removed all accounts due API character limit.
      // if (displayAccounts.length > 0) {
      //   queryParams += arrayToString(displayAccounts, 'pickupAccount', 'accountId');
      // }

      // Sending only selected account.
      if (selectedAccount?.accountId) {
        queryParams += `&pickupAccount=${selectedAccount?.accountId}`;
      }
      if (!destinationCountry.iossApplies) {
        getInternationalProductsApi(url, queryParams);
      } else {
        if (parseFloat(formikValues.subTotal) > 0) {
          getInternationalProductsApi(url, queryParams);
        }
        else {
          setInternationalProductList?.([]);
        }
      }
    } else if (
      isTemplate ||
      isOrderDefaults ||
      (validationStatus && isValidObject(noOfItems) && weight > 0 && noOfItems > 0)
    ) {
      const destinationPostalCode = postalCode || 'CV37 6UH';
      const destinationRecipientType = addressType || 'business';
      const parcels = noOfItems || 1;
      const weightDefined = weight || 1;
      const destinationAddressType = deliveryType === 'deliverToDoorStep' ? 'doorstep' : 'servicePoint';

      queryParams = `originCountryCode=GB&originPostalCode=${selectedAccount?.sender?.postalCode}&destinationCountryCode=GB&destinationPostalCode=${destinationPostalCode}&destinationRecipientType=${destinationRecipientType}&parcels=${parcels}&weight=${weightDefined}&destinationAddressType=${destinationAddressType}`;
      queryParams = availableProductType?.toString?.()
        ? `${queryParams}&accountTypes=${availableProductType.toString()}`
        : queryParams;

      // Removed all accounts due API character limit.
      // if (displayAccounts.length > 0) {
      //   queryParams += arrayToString(displayAccounts, 'pickupAccount', 'accountId');
      // }

      // Sending only selected account.
      if (selectedAccount?.accountId) {
        queryParams += `&pickupAccount=${selectedAccount?.accountId}`;
      }
      domesticProductAPI(url + queryParams);
    } else {
      setInternationalProductList?.([]);
      dispatch(actions.setAllAvailableProducts([]));
      setProducts([]);
      setServices([]);
    }
  }, [
    formikValues.destinationCountry,
    formikValues.noOfItems,
    formikValues.weight,
    formikValues.postalCode,
    formikValues.addressType,
    formikValues.deliveryType,
    formikValues.customDeclarationCurrency,
    formikValues.subTotal,
    selectedAccount,
    formikValues.customDeclarationValue,
    formikValues.applyIossNumber
  ]);

  const domesticProductAPI = useDebouncedCallback((url) => {
    abortOnGoingCallsFor(url);
    axiosConfig
      .get(url)
      .then((response) => {
        let allProducts = response.data;
        allProducts?.sort?.((a, b) => basicSort(a.sortOrder, b.sortOrder));
        dispatch(actions.setAllAvailableProducts(allProducts));
      })
      .catch((error) => {
        dispatch(actions.setAllAvailableProducts([]));
        setProducts([]);
        setServices([]);
      });
  }, 400);

  useEffect(() => {
  if(formikValues?.destinationCountry?.code2Digit === 'GB')
    {
    if (allProducts.length > 0) {
      if (formikValues.deliveryType === 'deliverToServicePoint') {
        setProducts(allProducts);
      }
      else if (formikValues.createPalletShipment === 'yes') {
        setProducts(allProducts.filter((p) => p.deliveryCapabilities?.pallets));
      }
      else {
        switch (formikValues.doorStepInstructions.value) {
          case 'deliverToNeighbour':
            setProducts(allProducts.filter((p) => p.deliveryCapabilities?.neighbourDelivery));
            break;
          case 'leaveSafe':
            setProducts(allProducts.filter((p) => p.deliveryCapabilities?.secureLocationDelivery));
            break;
          case 'deliverToDoorStepOnly':
            setProducts(
              allProducts.filter(
                (p) => !p.deliveryCapabilities?.neighbourDelivery && !p.deliveryCapabilities?.secureLocationDelivery && !p.deliveryCapabilities?.pallets,
              )
            );
            break;
          default:
            setProducts(allProducts.filter((p) => !p.deliveryCapabilities?.pallets));
        }
      }
    }
  }
  }, [allProducts, formikValues.doorStepInstructions, formikValues.createPalletShipment]);


  const getInternationalProductsApi = useDebouncedCallback((url, queryParams) => {
    abortOnGoingCallsFor(url);
    axiosConfig
      .get(url + queryParams)
      .then((response) => {
        let airProducts = [];
        let roadProduct = [];
        const allProduct = [];

        if (formikValues.destinationCountry.iossApplies) {
          if (formikValues?.applyIossNumber === 'true') {
            airProducts = response.data.filter(obj => obj.deliveryCapabilities.accountType === 'internationalAir' && obj.deliveryCapabilities.iossApplies === true).sort((a, b) => a.sortOrder >= b.sortOrder ? 1 : -1)
            roadProduct = response.data.filter(obj => obj.deliveryCapabilities.accountType === 'internationalRoad' && obj.deliveryCapabilities.iossApplies === true).sort((a, b) => a.sortOrder >= b.sortOrder ? 1 : -1)
          }
          else {
            airProducts = response.data.filter(obj => (obj.deliveryCapabilities.accountType === 'internationalAir' && (obj.deliveryCapabilities.iossApplies === false ||
              (obj.deliveryCapabilities.iossApplies === true && obj.deliveryCapabilities.nonIossAllowed === true))))
              .sort((a, b) => a.sortOrder >= b.sortOrder ? 1 : -1)

            roadProduct = response.data.filter(obj => (obj.deliveryCapabilities.accountType === 'internationalRoad' && (obj.deliveryCapabilities.iossApplies === false ||
              (obj.deliveryCapabilities.iossApplies === true && obj.deliveryCapabilities.nonIossAllowed === true))))
              .sort((a, b) => a.sortOrder >= b.sortOrder ? 1 : -1)

          }
        }
        else {
          airProducts = response.data.filter((x) => x.deliveryCapabilities.accountType === 'internationalAir').sort((a, b) => a.sortOrder >= b.sortOrder ? 1 : -1);
          roadProduct = response.data.filter((x) => x.deliveryCapabilities.accountType === 'internationalRoad').sort((a, b) => a.sortOrder >= b.sortOrder ? 1 : -1);
        }
        // if (roadProduct.length > 0) {
        //   allProduct.push(roadProduct[0]);
        // }
        // if (airProducts.length > 0) {
        //   allProduct.push(airProducts[0]);
        // }
        roadProduct.map((ele)=> allProduct.push(ele));
        airProducts.map((ele)=> allProduct.push(ele));

        dispatch(actions.setAllAvailableProducts(allProduct));
        
        if(formikValues.destinationCountry.iossApplies){
          setInternationalProductList?.(allProduct.filter((ele)=>ele.reasonForExportList.filter((x)=> x.type === formikValues.reasonForExport?.type).length>0));
        }
        else{
          setTimeout(() => {
            setInternationalProductList?.(allProduct);
          }, 0);
        }
      })
      .catch((error) => {
        setInternationalProductList?.([]);
      });
  }, 400);

  useEffect(()=> {
    setInternationalProductList?.(allProducts.filter((ele)=>ele.reasonForExportList.filter((x)=> x.type === formikValues.reasonForExport?.type).length>0));
  },[formikValues.reasonForExport, allProducts]);

  useEffect(() => {
    setFieldValue('product', setDropdownValues(products, 'productNameForUI', 'parcel'));
  }, [products]);

  useEffect(() => {
    setServices(formikValues.product?.deliveryCapabilities?.services || []);
  }, [formikValues.product]);

  useEffect(() => {
    setFieldValue('service', setDropdownValues(services, 'name', 'next day'));
  }, [services]);

  useEffect(() => {
    const loadedFromScreen = loadedFrom.loadedFrom;
    const isFromDropDown = loadedFromScreen === 'dropdownResponse';
    const loadedResponse = loadedFrom.loadedResponse;
    if (
      (products || []).length > 0 &&
      (loadedFromScreen === 'dropdownResponse' || loadedFromScreen === 'fromPreviewSummary') &&
      loadedFrom?.loadedResponse?.product
    ) {
      const selectedProduct = setDropdownValues(
        products,
        'productCode',
        isFromDropDown ? loadedResponse.product : loadedResponse.product?.productCode,
      );
      setFieldValue('product', selectedProduct);
    } else if ((products || []).length > 0 && Object.entries(templateResponse).length > 0 && templateResponse.product) {
      const selectedProduct = setDropdownValues(
        products,
        'productCode',
        templateResponse.product?.productCode ? templateResponse.product?.productCode : templateResponse.product,
      );
      setFieldValue('product', selectedProduct);
    }
  }, [products, loadedFrom]);

  useEffect(() => {
    const loadedFromScreen = loadedFrom.loadedFrom;
    const isFromDropDown = loadedFromScreen === 'dropdownResponse';
    const loadedResponse = loadedFrom.loadedResponse;
    if (
      services?.length > 0 &&
      (loadedFromScreen === 'dropdownResponse' || loadedFromScreen === 'fromPreviewSummary') &&
      (loadedResponse || {}).service
    ) {
      const selectedService = setDropdownValues(
        services,
        'name',
        isFromDropDown ? loadedResponse.service : loadedResponse.service?.name,
      );
      setFieldValue('service', selectedService);
      // dispatch(actions.setLoadedFrom({ loadedFrom: '' }));
    } else if (services?.length > 0 && Object.entries(templateResponse).length > 0 && templateResponse.service) {
      const selectedService = setDropdownValues(
        services,
        'name',
        templateResponse.service?.name ? templateResponse.service?.name : templateResponse.service,
      );
      setFieldValue('service', selectedService);
    }
  }, [services, loadedFrom]);

  useEffect(() => {
    if (loadedFrom?.loadedResponse && Object.entries(loadedFrom?.loadedResponse).length > 0)
      setTemplateResponse(loadedFrom.loadedResponse);
  }, [loadedFrom]);

  useEffect(() => {
    return () => {
      dispatch(actions.setAllAvailableProducts([]));
    };
  }, []);

  return (
    <Grid container className="productservice-main-grid">
      {isDomestic ? (
        <Grid container spacing={6} direction="row" alignItems="flex-start">
          <Grid item xs={10} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
            <FormRenderer {...product.props} data={products} fullWidth></FormRenderer>
          </Grid>
          <Grid item xs={10} sm={6} md={6} lg={6} style={{ paddingLeft: '15px' }}>
            <FormRenderer {...service.props} data={services} fullWidth />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <InternationalProduct
            productList={internationalProductList}
            initialValue={internationalProductList.length > 0 ? internationalProductList[0].productCode : null}
            setFieldValue={setFieldValue}
            formikValues={formikValues}
          />
        </Grid>
      )}
    </Grid>
  );
};

ProductAndServiceDetails.propTypes = {
  formField: PropTypes.object,
  isDomestic: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
    displayAccounts: state.accounts.displayAccounts,
    userAccounts: state.accounts.userAccounts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (loadedFrom) => {
      dispatch(actions.setLoadedFrom(loadedFrom));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductAndServiceDetails);
