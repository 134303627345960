import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  servicePointDelivery: null,
  shipmentDetails: null,
  iossValueLimits: null,
  orderResponseData: {},
};

const setAddShipmentData = (state, action) => {
  return updateObject(state, { shipmentDetails: action.data });
};

const setServicePointDelivery = (state, action) => {
  return updateObject(state, {
    servicePointDelivery: action.data,
  });
};

const setIossValueLimits = (state, action) => {
  return updateObject(state, {
    iossValueLimits: action.data,
  });
};

const setOrderResponseData = (state, action) => {
  return updateObject(state, {
    orderResponseData: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETADDSHIPMENTDATA:
      return setAddShipmentData(state, action);
    case actionTypes.SET_DELIVERYSERVICEPOINT:
      return setServicePointDelivery(state, action);
    case actionTypes.SET_IOSSVALUELIMIT:
      return setIossValueLimits(state, action);
    case actionTypes.SET_ORDERRESPONSE:
      return setOrderResponseData(state,action);
    default:
      return state;
  }
};

export default reducer;
