import { Box, Button, FormControl, Grid, Input, InputAdornment, InputLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { axiosConfig } from '../../../../api/axios';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import languageEncoding from "detect-file-encoding-and-language"
import {
  schemaValues,
  importTypeValues,
  dateOptions,
  delimiterOptions,
  MAX_ROWS_FOR_SCHEMA,
} from '../../../../utils/constants/constants';
import { getCustomerAccountID } from '../../../../utils/helperFunctions';
import CloudUploadSharpIcon from '@material-ui/icons/CloudUploadSharp';
import { ReactComponent as UploadIcon } from '../../../../../src/assets/images/upload-gray.svg';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import { useDebouncedCallback } from 'use-debounce/lib';

const SchemaDetailsField = (props) => {
  const {
    formField: {
      schemaName,
      schemaType,
      importType,
      dateFormat,
      isheaderIncluded,
      delimiter,
      otherformat,
      newDelimeter,
      pollingPath,
      fileExtension,
    },

    setFieldValue,
    setValues,
    oldSchemaName,
    errors,
    setFieldTouched,
    currentSelectedValue,
    currentValueStartLength,
    dropdownValues,
    setCurrentValue,
    setCurrentValueStartLength,
    formikValues,
    currentRowIndicatorValue,
    currentRowIndicatorPlacement,
    uploadedFile,
    setUploadedFile,
    isDefaultSchema,
    setIsDefaultSchema,
    setSchemaNameExist,
    setFolderPathExist,
    existingFolderPaths,
    folderPathExist
  } = props;
  const dispatch = useDispatch();
  const [isOtherFormat, setOtherFormat] = useState(false);
  const [isDelimeter, setDelimeter] = useState(false);
  const [allRowsWithColumns, setAllRowsWithColumns] = useState([]);
  const [existingSchema, setExistingSchema] = useState(false);
  const [fileError, setFileError] = useState(false);
  let allRows = [];
  let allDropDownValues = [];


  useEffect(() => {
    if (oldSchemaName != undefined) {
      dispatch(actions.setLoaderLayOver(true));
      getSchemaDetails(oldSchemaName)
    }
  }, [oldSchemaName]);

  useEffect(() => {
    if (formikValues.dateFormat.value != 'other') {
      setFieldValue('otherformat', '');
      setOtherFormat(true);
    } else {
      setOtherFormat(false);
    }
    if ((formikValues.delimiter || undefined) && formikValues.delimiter.value === 'otherDelimiter') {
      setDelimeter(true);
    } else {
      setFieldValue('newDelimeter', '');
      setDelimeter(false);
    }
  }, [formikValues.dateFormat, formikValues.delimiter]);

  useEffect(() => {
    if (oldSchemaName === undefined) {
      getSchemaDetails(formikValues.schemaName)
    }
  }, [formikValues.schemaName])
  // useEffect(() => {

  const ifNameExist = useDebouncedCallback((name) => {
    const encodeURIName = encodeURIComponent(name)
    axiosConfig
      .get(
        `${ServiceEndPoints.getSchemas}?schemaName=${encodeURIName
        }&organization=${getCustomerAccountID()}&isExactMatch=true`,
      )
      .then((res) => {
        if (res.data.orderFileSchemas?.[0]?.isDefault) {
          setIsDefaultSchema(true)
        }
        else {
          setIsDefaultSchema(false)
        }
        if (oldSchemaName != undefined) {
          dispatch(
            actions.setShipmentId({
              shipmentId: res.data.orderFileSchemas[0].schemaName,
            }),
          );
          getSchemaDetailsForFields(res.data.orderFileSchemas[0]);
        } else {
          setExistingSchema(true)
          setSchemaNameExist(true)
        }

      })
      .catch((err) => {
        console.log(err);
        setExistingSchema(false)
        setSchemaNameExist(false)
        // resetForm();
        // setFieldValue('pollingPath', '');
      });
  }, 500)

  const getSchemaDetails = (name) => {
    if (name !== '') {
      ifNameExist(name);
    }
  }

  // }, []);


  const getSchemaDetailsForFields = (orderFileSchemas) => {
    props.getResultedSchemaDetails(orderFileSchemas);
    const newObj = {}
    newObj["schemaName"] = orderFileSchemas.schemaName;
    newObj['pollingPath'] = orderFileSchemas.filePollingFolder;

    newObj['isheaderIncluded'] = orderFileSchemas.isheaderIncluded;

    newObj['fileExtension'] = orderFileSchemas.fileExtension ? orderFileSchemas.fileExtension : '';
    if (orderFileSchemas.schemaFileType.fileType === 2) {
      newObj['importType'] = importTypeValues[1].value;
    } else {
      newObj['importType'] = importTypeValues[0].value;
    }

    //this is for schemaType
    newObj['schemaType'] = schemaValues.find(val => val.value === orderFileSchemas.schemaType);

    //this is for dateFormat
    if (orderFileSchemas.date === 'other') {
      newObj['dateFormat'] = dateOptions[2];
    } else if (orderFileSchemas.date === 'M/d/yyyy') {
      newObj['dateFormat'] = dateOptions[1];
    } else if (orderFileSchemas.date === 'd/M/yyyy') {
      newObj['dateFormat'] = dateOptions[0];
    } else {
      newObj['dateFormat'] = dateOptions[2];
      newObj['otherformat'] = orderFileSchemas.date;
    }

    //this is for delimiter

    if (orderFileSchemas.schemaFileType.delimiter === ',') {
      newObj['delimiter'] = delimiterOptions[0];
    } else if (orderFileSchemas.schemaFileType.delimiter === '~') {
      newObj['delimiter'] = delimiterOptions[1];
    } else if (orderFileSchemas.schemaFileType.delimiter === '|') {
      newObj['delimiter'] = delimiterOptions[2];
    } else if (orderFileSchemas.schemaFileType.delimiter === '\\t' || orderFileSchemas.schemaFileType.delimiter === "\t") {
      newObj['delimiter'] = delimiterOptions[3];
    } else if (orderFileSchemas.schemaFileType.delimiter === ';') {
      newObj['delimiter'] = delimiterOptions[4];
    } else {
      newObj['delimiter'] = delimiterOptions[5];
      newObj['newDelimeter'] = orderFileSchemas.schemaFileType.delimiter;
    }
    setValues({ ...formikValues, ...newObj });
    dispatch(actions.setLoaderLayOver(false));
  };

  const isMultipleSchema = () => {
    return formikValues.schemaType?.value !== 'order' && formikValues.schemaType?.value !== 'collection' && formikValues.schemaType.value !== 'custom' && formikValues.schemaType?.value !== "order+collectionsConsignor" && formikValues.schemaType?.value !== 'parcel' && formikValues.schemaType?.value !== "clearance" ? true : false;
  };

  const setAllRowsInFile = (string) => {
    const lastIndex = string.indexOf('\n');
    if (allRows.length <= MAX_ROWS_FOR_SCHEMA) {
      if (lastIndex !== -1) {
        allRows.push(string.slice(0, lastIndex));
        setAllRowsInFile(string.slice(lastIndex + 1, string.length));
      } else if (string.length > 0) {
        allRows.push(string);
      }
    }
  };

  const setPageDataFromFile = (file) => {
    setAllRowsWithColumns([]);
    props.setDropdownValues([]);
    //read file as text and form dropdownvalues and show content of file
    if (file) {
      let asText = new FileReader();
      languageEncoding(file).then((fileInfo) => {
        asText.readAsText(file, fileInfo.encoding || "latin1");
      })

      asText.onload = () => {
        setAllRowsInFile(asText.result);
        if (formikValues.importType === 'fixedlength') {
          if (currentRowIndicatorValue) {
            allRows = allRows.filter(row => {
              if (row.split("  ").filter(f => f)
                .find(value => value.trim().toLowerCase() === currentRowIndicatorValue.trim().toLowerCase())) { return true }
              return false
            })
          }
          setAllRowsWithColumns(allRows);
        } else {
          const selectedDel =
            formikValues.delimiter.value === 'otherDelimiter'
              ? formikValues.newDelimeter
              : formikValues.delimiter.value.replace("\\t", "\t");
          allRows = allRows
            .filter((val, index) => {
              let values = val.split(selectedDel);
              if (isMultipleSchema()) {
                if (currentRowIndicatorValue) {
                  if (values.find(val => val.toLowerCase() === currentRowIndicatorValue.toLowerCase())) {
                    return true;
                  } else return false
                } else return true;

              }
              else return true;
            })
            .map((row) => {
              let values = row.split(selectedDel);
              return values;
            });
          allRows = allRows.map((val) => {
            let newArr = [];
            let start;
            let flag = true;
            val.forEach((a, i) => {
              if (selectedDel === "," && a.includes("\"") && a.split("\"").length === 2) {
                flag = false;
                if (start && start != i) {
                  newArr.push(val.slice(start, i + 1).join(`${selectedDel} `));
                  flag = true;
                  start = null;
                }
                else start = i;
              }
              else if (flag) {
                newArr.push(a);
              }
            });
            return newArr;
          });

          if (allRows.length > 0) {
            if (formikValues.isheaderIncluded) {
              allDropDownValues = allRows[0].map((v) => {
                return { name: v, value: v };
              });
            }
            else if (isMultipleSchema()) {
              if (currentRowIndicatorValue) {
                if (allRows[0].find(val => val.toLowerCase() === currentRowIndicatorValue.toLowerCase())) {
                  allDropDownValues = allRows[0].map((v, index) => {
                    return { name: `Col${index + 1}`, value: index + 1 };
                  });
                }
              } else {
                allDropDownValues = allRows[0].map((v, index) => {
                  return { name: `Col${index + 1}`, value: index + 1 };
                });
              }
            }
            else {
              allDropDownValues = allRows[0].map((v, index) => {
                return { name: `Col${index + 1}`, value: index + 1 };
              });
            }
          }

          setAllRowsWithColumns(allRows);
          if (allDropDownValues.length > 1) {
            allDropDownValues.unshift({
              value: '',
              name: 'Select',
            });
            props.setDropdownValues(allDropDownValues);
          } else {
            props.setDropdownValues([]);
          }
        }
      };
    }
  };

  const getFileDetails = (e) => {
    const files = e.target.files;
    const file = files[0];
    const fileName = file.name.split('.').pop().toLowerCase();
    const allowedExtensions = ['.csv', '.txt'];
    const allowedMIMETypes = ['text/csv', 'text/plain'];
    if (allowedExtensions.includes('.' + fileName) && allowedMIMETypes.includes(file.type)) {
      setUploadedFile(file);
      setPageDataFromFile(file);
      setFileError(false);
    }
    else{
      setFileError(true);
    }
  };

  const resetFileSelection = (e) => {
    e.target.value = '';
  };

  const handleClick = (e) => {
    setUploadedFile(null);
    setPageDataFromFile(null);
    resetFileSelection(e);
  };

  useEffect(() => {
    setCurrentValue('');
    setCurrentValueStartLength([0, 0]);
    if (uploadedFile && (formikValues.delimiter.value != 'otherDelimiter' || formikValues.newDelimeter.trim())) {
      setPageDataFromFile(uploadedFile);
    } else if (!uploadedFile) {
      setPageDataFromFile(null);
    }
  }, [
    uploadedFile,
    formikValues.isheaderIncluded,
    formikValues.delimiter,
    formikValues.newDelimeter,
    formikValues.schemaType,
    formikValues.importType,
    currentRowIndicatorValue
  ]);

  const sliceText = (text, start, end) => {
    return text.slice(start, end);
  };

  useEffect(() => {
    if (isMultipleSchema()) {
      setFieldValue('isheaderIncluded', false);
    }
  }, [formikValues.schemaType, formikValues.importType]);


  useEffect(() => {
    if (formikValues.pollingPath?.trim()) {
      if (existingFolderPaths.find(path => path === formikValues.pollingPath)) {
        setFolderPathExist(true)
      } else setFolderPathExist(false)
    }
  }, [formikValues.pollingPath]);

  return (
    <div className="wd-100">
      <Grid className="schema-detail-row1" container spacing={3} direction="row" alignItems="flex-start">
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <FormRenderer {...schemaName.props} fullWidth isDisabled={oldSchemaName != undefined || isDefaultSchema} />
          <span className="span-required" style={{ display: existingSchema && oldSchemaName === undefined ? '' : 'none' }}>
            Schema name already exists
          </span>
        </Grid>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <FormRenderer {...schemaType.props} isDisabled={isDefaultSchema} data={props.mappingDetails?.schemaType !== 'order+collectionsConsignor' ? schemaValues.filter((ele) => ele.value !== 'order+collectionsConsignor') : schemaValues} fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <FormRenderer {...pollingPath.props} fullWidth />
          <span className="span-required" style={{ display: folderPathExist ? 'block' : 'none' }}>
            File polling path already exists
          </span>
        </Grid>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <Grid container spacing={3} direction="row" alignItems="flex-start">
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <FormRenderer {...dateFormat.props} isDisabled={isDefaultSchema} data={dateOptions} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              {!isOtherFormat ? <FormRenderer   {...otherformat.props} isDisabled={isDefaultSchema} fullWidth /> : null}

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box component="div" style={{ paddingTop: '20px', paddingBottom: '15px' }}>
            <b>Import file format</b>
          </Box>
        </Grid>
      </Grid>
      <Grid className="import-type-row" container spacing={3} direction="row" alignItems="flex-start">
        <Grid className="import-type" spacing={3} container direction="row">
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <FormRenderer {...importType.props} isDisabled={isDefaultSchema} data={importTypeValues} />
          </Grid>
          <Grid item xs={6} sm={6} md={5} lg={5}>
            <Grid container spacing={3} direction="row" alignItems="flex-start">
              {formikValues.importType !== 'fixedlength' ? (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormRenderer {...delimiter.props} isDisabled={isDefaultSchema} data={delimiterOptions} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {isDelimeter ? <FormRenderer {...newDelimeter.props} isDisabled={isDefaultSchema} fullWidth /> : ''}
                  </Grid>{' '}
                </>
              ) : null}

            </Grid>
          </Grid>
        </Grid>
        <Grid className="upload-row" xs={12} sm={12} md={12} lg={12} spacing={3} container direction="row">
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Grid className="isrow-header" container>
              <FormRenderer isDisabled={isMultipleSchema() || isDefaultSchema} {...isheaderIncluded.props} fullWidth />
              {/* </Grid>
          <Grid className="upload-eg-file" item xs={12} sm={12} md={3} lg={3}> */}
              <Grid className="upload-eg-file" >
                <span style={{ position: 'relative', top: '12px' }} >
                  <input
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={(event) => getFileDetails(event)}
                    onClick={resetFileSelection}
                    disabled={isDefaultSchema}
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="raised" component="span" disabled={isDefaultSchema}>
                      {/* <CloudUploadSharpIcon icon="upload" /> */}
                      <UploadIcon />
                    </Button>
                  </label>
                </span>
                <span className="textfield">
                  <FormControl style={{ maxWidth: '198px' }}>
                    <InputLabel htmlFor="standard-adornment-password">Example upload file</InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type="text"
                      value={uploadedFile?.name || ''}
                      disabled={isDefaultSchema}
                      endAdornment={
                        uploadedFile?.name ? (
                          <InputAdornment position="end">
                            <CloseSharpIcon aria-label="toggle password visibility" onClick={handleClick}></CloseSharpIcon>
                          </InputAdornment>
                        ) : (
                          ''
                        )
                      }
                    />
                  </FormControl>
                </span>
                <span className="span-required" style={{ display: fileError ? 'block': 'none', marginTop:'17px'}}>
            Invalid format. Please upload a .txt or .csv file
          </span>
              </Grid>
              
            </Grid>
            
          </Grid>
          <Grid item xs={6} sm={6} md={5} lg={5}>
            <Grid container spacing={3} direction="row" style={{ margin: '0px' }} alignItems="flex-start">
              <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: '8px' }} className="fileExtensionContainer" >
                <FormRenderer {...fileExtension.props} fullWidth />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="upload-result" container spacing={3} direction="row">
        <div>
          {allRowsWithColumns.length > 0 ? (
            <table style={{ backgroundColor: 'lightgray' }}>
              {!Array.isArray(allRowsWithColumns[0])
                ? allRowsWithColumns.map((row, index) => {
                  let splittedElements = [];
                  const start = currentValueStartLength[0] - 1 <= 0 ? 0 : currentValueStartLength[0] - 1;

                  if (start === 0) {
                    splittedElements.push('');
                  } else {
                    splittedElements.push(sliceText(row, 0, currentValueStartLength[0] - 1));
                  }
                  if (currentValueStartLength[0] + currentValueStartLength[1] === row.length) {
                    splittedElements.push('');
                  } else {
                    splittedElements.push(sliceText(row, start + currentValueStartLength[1], row.length));
                  }
                  const highlight = sliceText(row, start, start + currentValueStartLength[1]);

                  return (
                    <tr>
                      <td key={index}>
                        <span>{splittedElements[0]}</span>
                        <span className="red">{highlight}</span>
                        <span>{splittedElements[1]}</span>
                      </td>
                    </tr>
                  );
                })
                : allRowsWithColumns.map((row) => {
                  return (
                    <tr className={(formikValues.isheaderIncluded) ? 'isHighlightFirstRow' : ''}>
                      {row.map((col, index) => {
                        return (
                          <td
                            key={index}
                            className={
                              dropdownValues?.[index + 1]?.value.toString() === currentSelectedValue.toString()
                                ? 'red'
                                : ''
                            }
                          >
                            {col}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </table>
          ) : null}
        </div>
      </Grid>
    </div>
  );
};

export default SchemaDetailsField;
