import React, { useState, useRef, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { GET } from './../../../api/axios';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useDebouncedCallback } from 'use-debounce';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';

const AddressSearch = (props) => {
  const { setAddressValues, showError, isDisabled } = props;
  const [postalSearchData, setPostalSearchData] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [type, setType] = useState();
  let searchInput = useRef(null);

  const debounced = useDebouncedCallback((value) => {
    getAddresses(value);
  }, 500);

  const getAddresses = (searchedString = '', queryParams = '') => {

    GET(`${ServiceEndPoints.getPostalAddress}/find?text=${encodeURI(searchedString)}&countrycode=${props.destinationCountrycode2Digit}${queryParams}`)
      .then((res) => {
        setPostalSearchData(res.data);

        setIsOptionOpen(true);
      })
      .catch((err) => {
        console.log('Address not found: ', err);
      });
  };

  const rowClicked = (val) => {
    setIsOptionOpen(false);
    GET(`${ServiceEndPoints.getPostalAddress}/retrieve?id=${val.id}`)
      .then((res) => {
        setType(res.data[0].type)
        setInputValue('');
        setValue(null);
        setAddressValues(res.data[0]);
      })
      .catch((err) => {
        alert(`Error :${err}`);
      });
  };

  React.useEffect(() => {
    if (inputValue.trim() === '') {
      setPostalSearchData([]);
      return undefined;
    } else if ((inputValue.length > 4 && props.isDomestic) || (inputValue.length > 2 && !props.isDomestic)) {
      if (value) {
        if (value.type === 'Address') {
          rowClicked(value);
        } else {
          getAddresses(value.text, `&container=${value.id}`);
        }
      } else {
        debounced(inputValue);
      }
    }
  }, [value, inputValue]);

  useEffect(() => {
    document.getElementById('address-search').setAttribute('autocomplete', 'new-password');
  }, []);

  useEffect(() => {
    setType()
  }, [props?.addressType])
  return (
    <Autocomplete
      id="address-search"
      disabled={isDisabled}
      noOptionsText={'No search results'}
      getOptionLabel={(option) => `${option.text} ${option.description}`}
      filterOptions={(x) => x}
      options={postalSearchData}
      open={isOptionOpen}
      autoComplete={false}
      onOpen={() => {
        setIsOptionOpen(true);
      }}
      onClose={() => {
        setIsOptionOpen(false);
      }}
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setPostalSearchData(newValue ? [newValue, ...postalSearchData] : postalSearchData);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        props.onSelectAddress(type)
      }}
      renderInput={(params) => (
        <>
          <TextField
            ref={searchInput}
            {...params}
            label="Start typing your address or postcode"
            variant="outlined"
            fullWidth
          />
          {showError && <span className="span-required">Pallets can only be delivered to business address</span>}

        </>
      )}
      renderOption={(option) => {
        const matches = match(`${option.text} ${option.description}`, inputValue);
        const parts = parse(`${option.text} ${option.description}`, matches);
        return (
          <Grid container alignItems="center">
            <Grid item>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

AddressSearch.propTypes = {
  setAddressValues: PropTypes.func,
};

export default AddressSearch;
