import * as actionTypes from './actionsTypes';

export const setAddShipmentData = (data) => {
  return {
    type: actionTypes.SETADDSHIPMENTDATA,
    data,
  };
};

export const setServiceLocationDetails = (data) => {
  return {
    type: actionTypes.SET_DELIVERYSERVICEPOINT,
    data,
  };
};

export const setIossValueLimits = (data) => {
  return {
    type:actionTypes.SET_IOSSVALUELIMIT,
    data,
  }
}

export const setOrderResponse = (data)=>{
  return{
    type:actionTypes.SET_ORDERRESPONSE,
    data:data
  }
}