/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import './parcelDetails.scss';
import { SearchIcon } from '@material-ui/data-grid';
import { axiosConfig } from '../../../../api/axios';
import CustomDialog from '../../../../framework/dialog/customDialog';
import AddDimension from '../../../../components/addShipment/ParcelDetails/AddDimension';
import AddDomesticDimension from '../../../../components/addShipment/ParcelDetails/AddDomesticDimension';
import InternationalParcelDetails from '../../../../components/addShipment/ParcelDetails/InternationalParcelDetails/InternationalParcelDetails';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../../../framework/modal/modal';
import CustomDataTable from '../../../../framework/CustomDataTable/CustomDataTable';
import { ReactComponent as EditIcon } from '../../../../assets/images/EditIcon.svg';
import { ServicePointError, NoOfRows } from '../../../../utils/constants/constants';
import { connect } from 'react-redux';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import * as actions from '../../../../store/actions/index';
import { useDebouncedCallback } from 'use-debounce';
let dimsValidation = {};
const ParcelDetails = (props) => {
  const {
    formField: {
      noOfItems,
      weight,
      addParcelDimensions,
      duplicateParcelDimensions,
      servicePoint,
      length,
      width,
      height,
      pieceWeight,
      createPalletShipment,
      pallets,
    },
    setFieldValue,
    errors,
    setFieldTouched,
    isDomestic,
    isTemplate,
    isAddressBook,
    formikValues,
    loadedFrom,
    isOrderDefaults,
    setShowDeliveryOptions,
    isAmendShipment,
    setErrors,
    setReasonsForExport,
    setAfterSubmitFlag,
    afterSubmitFlag,
    setIsServicePointAddress,
    isServicePointAddress
  } = props;
  const servicePointDetails = useSelector((state) => state.addShipmentData.servicePointDelivery);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [servicePoints, setservicePoints] = React.useState([]);
  const [oldParcelDetails, setOldParcelDetails] = React.useState([]);
  const [volumetricWeightDivisor, setVolumetricWeightDivisor] = useState(4000);
  const [fromAddshipfirstclick, setFromAddshipfirstclick] = useState(true);
  const [showPalletField, setShowPalletField] = useState(false);
  const [showDimension, setShowDimension] = useState(false);
  const [isPalletsAutoSwitched, setIPalletsAutoSwitched] = useState(false);
  const [totalValues, setTotalValues] = useState({ totalWeight: 0.0, totalVolumetricWeight: 0.0 });

  const { destinationCountry, handOverMethod,accountNumber,postalCode } = formikValues;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [intlDimsValidation, setIntlDimsValidation] = useState(null);
  const [clearancePallet, setClearancePallet] = useState(false);
  useEffect(() => {
    if ((handOverMethod === 'servicePoint' || isServicePointAddress) && (formikValues?.weight < 21 && !isDomestic)) {
      setFieldValue('noOfItems', 1);
      setIsServicePointAddress(false);
    }
    // if ((loadedFrom === '' || (loadedFrom || {}).loadedFrom === '' || !isDomestic) && !isOrderDefaults) {
    //   setFieldValue('noOfItems', 1);
    // }
  }, [destinationCountry, formikValues.addParcelDimensions, handOverMethod , isServicePointAddress]);  

  useEffect(() => {
    // if (isDomestic && formikValues.addParcelDimensions && (loadedFrom === '' || (loadedFrom || {}).loadedFrom === '')) {
    if (isDomestic && formikValues.addParcelDimensions) {
      if (loadedFrom?.loadedFrom === 'dropdownResponse') {
        if (
          !(loadedFrom === '' || (loadedFrom || {}).loadedFrom === '') &&
          ((loadedFrom?.loadedResponse?.parcelDetails?.parcelDimensions?.length === 0 &&
            !formikValues.addParcelDimensions) ||
            (loadedFrom?.loadedResponse?.parcelDetails?.parcelDimensions?.length > 0 &&
              formikValues.addParcelDimensions))
        ) {
          setIsDialogOpen(false);
        } else {
          if (loadedFrom.loadedResponse?.shipments?.[0]?.pieces?.length === 0) {
            setDialogOpen();
            setFromAddshipfirstclick(false);
          }
          else if (loadedFrom?.loadedResponse?.fromAddship && fromAddshipfirstclick) {
            setIsDialogOpen(false);
            setFromAddshipfirstclick(false);
          } else {
            setDialogOpen();
          }
        }
      } else if (loadedFrom?.loadedFrom === 'fromPreviewSummary') {
        if (
          !(loadedFrom === '' || (loadedFrom || {}).loadedFrom === '') &&
          ((loadedFrom?.loadedResponse?.parcelDetails?.length === 0 && !formikValues.addParcelDimensions) ||
            (loadedFrom?.loadedResponse?.parcelDetails?.length > 0 && formikValues.addParcelDimensions))
        ) {
          setIsDialogOpen(false);
        } else {
          setDialogOpen();
        }
      } else {
        setDialogOpen();
      }
    } else {
      setIsDialogOpen(false);
    }
  }, [formikValues.addParcelDimensions]);

  useEffect(() => {
    if (servicePointDetails && (loadedFrom || {}).loadedFrom === 'fromPreviewSummary') {
      setFieldValue('servicePoint', servicePointDetails);
    } else {
      setFieldValue('servicePoint', formikValues.accountNumber?.sender?.postalCode ?? '');
    }
    setVolumetricWeightDivisor(formikValues?.accountNumber?.configuration?.volumetricWeightDivisor || 4000);
    // setVolumetricWeight();
  }, [formikValues.accountNumber]);

  const setDialogOpen = () => {
    setIsDialogOpen(true);
    resetLengthWidthHeight();
  };

  useEffect(() => {
    if (isDomestic && (loadedFrom === '' || (loadedFrom || {}).loadedFrom === '') && !isOrderDefaults) {
      !formikValues.weight && setFieldValue('weight', 0);
    } else if (isTemplate) {
      !formikValues.weight && setFieldValue('weight', '');
      !formikValues.noOfItems && setFieldValue('noOfItems', '');
    } else !formikValues.weight && setFieldValue('weight', '');
  }, [destinationCountry]);

  const debounceCountryFeature = useDebouncedCallback(() => {
    axiosConfig
      .get(`${ServiceEndPoints.productCountryFeature}?countrycode=${destinationCountry.code2Digit}`)
      .then((response) => {
        const nonPalletProducts = response.data.filter((val) => val.productCode !== 97 && val.productCode !== 98);
        const palletProducts = response.data.filter((val) => val.productCode === 97 || val.productCode === 98);

        if (destinationCountry.code2Digit !== 'GB') {
          let allLimitReasonForExportList = [];
          let allFullReasonForExportList = [];

          if (formikValues.accountNumber?.configuration?.limitReasonForExport === true) {
            nonPalletProducts.map((product) => {
              product.limitedReasonForExportList.map((rfe) => {
                !(allLimitReasonForExportList.find((ele) => ele.type === rfe.type)) &&
                  allLimitReasonForExportList.push(rfe);
              })
            })
            setReasonsForExport?.(allLimitReasonForExportList);
          }
          else {
            nonPalletProducts.map((product) => {
              product.fullReasonForExportList.map((rfe) => {
                !(allFullReasonForExportList.find((ele) => ele.type === rfe.type)) &&
                  allFullReasonForExportList.push(rfe);
              })
            })
            setReasonsForExport?.(allFullReasonForExportList);
          }
        }

        let allMaxParcelsList = nonPalletProducts.map((x) => x.maximumParcels);

        let allMaxHeightList = nonPalletProducts.map((x) => x.maximumHeight);
        let allMinHeightList = nonPalletProducts.map((x) => x.minimumHeight);

        let allMaxLengthList = nonPalletProducts.map((x) => x.maximumLength);
        let allMinLengthList = nonPalletProducts.map((x) => x.minimumLength);

        let allMaxWidhtList = nonPalletProducts.map((x) => x.maximumWidth);
        let allMinWidhtList = nonPalletProducts.map((x) => x.minimumWidth);

        let allMaxWeightList = nonPalletProducts.map((x) => x.maximumWeight);
        let allMinWeightList = nonPalletProducts.map((x) => x.minimumWeight);
        let allMaxItemWeight = nonPalletProducts.map((x) => x.maximumWeightPerParcel);

        let allMinWeightPallet = palletProducts.map((x) => x.minimumWeight);
        let allMaxItemWeightPallet = palletProducts.map((x) => x.maximumWeightPerParcel);
        let allMaxWeightListPallet = palletProducts.map((x) => x.maximumWeight);

        let allMaxPalletsList = palletProducts.length !== 0 ? palletProducts.map((x) => x.maximumPallets) : 1/0;

        dimsValidation = {
          ...dimsValidation,
          maxParcels: Math.max(...allMaxParcelsList),
          maxPallets: palletProducts.length !== 0 ? Math.max(...allMaxPalletsList) : '',
          maxHeight: Math.max(...allMaxHeightList),
          minHeight: Math.min(...allMinHeightList),
          maxLength: Math.max(...allMaxLengthList),
          minLength: Math.min(...allMinLengthList),
          maxWidth: Math.max(...allMaxWidhtList),
          minWidth: Math.min(...allMinWidhtList),
          maxWeight: Math.max(...allMaxWeightList),
          minWeight: Math.max(...allMinWeightList),
          // maxWeight: (formikValues.noOfItems || 1) * Math.max(...allMaxWeightList),
          maxItemWeight: Math.max(...allMaxItemWeight),
          maxItemWeightPallet: Math.max(...allMaxItemWeightPallet),
          maxWeightPallet: Math.max(...allMaxWeightListPallet),
          minWeightPallet: Math.min(...allMinWeightPallet),
        };

        setIntlDimsValidation(dimsValidation);
        weight.validation.validations = weight.validation.validations.filter(
          (x) => x.type !== 'max' && x.type !== 'min',
        );
        if (destinationCountry.code2Digit === 'GB') {

          pallets.validation.validations = pallets.validation.validations.filter(
            (x) => x.type !== 'max' && x.type !== 'min',
          );
          pallets.validation.validations.push({
            type: 'max',
            params: [dimsValidation.maxPallets, `Must be a value between 1 to ${dimsValidation.maxPallets}`],
          });
          pallets.props.inputProps.max = dimsValidation.maxPallets;
          pallets.validation.validations.push({
            type: 'min',
            params: [formikValues?.createPalletShipment === "yes" ? 1 : 0, `Must be a value between 1 to ${dimsValidation.maxPallets}`],
          })

          weight.validation.validations.push({
            type: 'when',
            params: [
              ['createPalletShipment', 'addressType', 'destinationCountry'],
              (createPalletShipment, addressType, destinationCountry, schema) => {
                if (
                  createPalletShipment === 'yes' &&
                  addressType === 'business' &&
                  destinationCountry?.code2Digit === 'GB'
                ) {
                  return schema.test('', 'Pallet not available for the selected weight', (value) => {
                    return value >= dimsValidation.minWeightPallet;
                  });
                }
              },
            ],
          });
          if(!isOrderDefaults){
          weight.validation.validations.push({
            type: 'max',
            params: [formikValues?.createPalletShipment === "yes" ? dimsValidation.maxItemWeightPallet :
              (isAmendShipment && (formikValues.deliveryType !== "deliverToDoorStep" || formikValues?.inboxReturnLabel === "true" || formikValues?.handOverMethod === "servicePoint")) ? 20 :
                dimsValidation.maxWeight, 'Weight is outside permitted range'],
          });
          
          if(destinationCountry.code2Digit === 'GB' && accountNumber?.sender?.postalCode?.substring(0, 2) != 'BT' &&
          postalCode?.substring(0, 2) == 'BT')
          {
            weight.validation.validations.push({
              type: 'max',
              params: [formikValues.noOfItems*31, `Parcel weight exceeded. Parcel weight limit ${formikValues.noOfItems*31}kg for delivery address`],
            });
          }
        }
        
          weight.validation.validations.push({
            type: 'min',
            params: [isAddressBook ? 0 : dimsValidation.minWeight, 'Weight is outside permitted range'],
          });
        } else {
          weight.validation.validations.push({
            type: 'max',
            params: [
              dimsValidation.maxWeight,
              `Declared weight must be less than or equal to ${dimsValidation.maxWeight}.`,
            ],
          });
          weight.validation.validations.push({
            type: 'min',
            params: [dimsValidation.minWeight, `Declared weight must be greater than ${dimsValidation.minWeight}`],
          });
        }

        noOfItems.validation.validations = noOfItems.validation.validations.filter(
          (x) => x.type !== 'max' && x.type !== 'min',
        );
        noOfItems.validation.validations.push({
          type: 'max',
          params: [dimsValidation.maxParcels, `Must be a value between 1 to ${dimsValidation.maxParcels}`],
        });
        noOfItems.validation.validations.push({
          type: 'min',
          params: [ 1 , `Must be a value between 1 to ${dimsValidation.maxParcels}`],
        })
        noOfItems.props.inputProps.max = dimsValidation.maxParcels;
        
        weight.props.inputProps.max = dimsValidation.maxWeight;

        length.validation.validations = length.validation.validations.filter(
          (x) => x.type !== 'max' && x.type !== 'min',
        );
        length.validation.validations.push({
          type: 'min',
          params: [
            dimsValidation.minLength,
            `Declared length must be greater than or equal to ${dimsValidation.minLength}`,
          ],
        });
        length.validation.validations.push({
          type: 'max',
          params: [
            dimsValidation.maxLength,
            `Declared length must be less than or equal to ${dimsValidation.maxLength}`,
          ],
        });
        width.validation.validations = width.validation.validations.filter((x) => x.type !== 'max' && x.type !== 'min');
        width.validation.validations.push({
          type: 'min',
          params: [
            dimsValidation.minWidth,
            `Declared width must be greater than or equal to ${dimsValidation.minWidth}`,
          ],
        });
        width.validation.validations.push({
          type: 'max',
          params: [dimsValidation.maxWidth, `Declared width must be less than or equal to ${dimsValidation.maxWidth}`],
        });
        height.validation.validations = height.validation.validations.filter(
          (x) => x.type !== 'max' && x.type !== 'min',
        );
        height.validation.validations.push({
          type: 'min',
          params: [
            dimsValidation.minHeight,
            `Declared height must be greater than or equal to ${dimsValidation.minHeight}`,
          ],
        });
        height.validation.validations.push({
          type: 'max',
          params: [
            dimsValidation.maxHeight,
            `Declared height must be less than or equal to ${dimsValidation.maxHeight}`,
          ],
        });

        pieceWeight.validation.validations = pieceWeight.validation.validations.filter(
          (x) => x.type !== 'max' && x.type !== 'min',
        );
        if (destinationCountry.code2Digit === 'GB') {
          pieceWeight.validation.validations.push({
            type: 'max',
            params: [dimsValidation.maxItemWeight, 'Weight is outside permitted range'],
            // params: [dimsValidation.maxItemWeight, 'Weight is outside permitted range'],
          });
          pieceWeight.validation.validations.push({
            type: 'min',
            params: [1, 'Weight is outside permitted range'],
          });
        }
        props.updateValidationSchema?.();
        if (!isDomestic) {
          setFieldValue('isFullCustomDetails', response.data?.[0]?.customsLevel === 'full');
          if (response.data?.some((feature) => feature?.addressType === 'servicePoint')) {
            setShowDeliveryOptions(true);
          } else {
            setShowDeliveryOptions(false);
            setFieldValue('deliveryType', 'deliverToDoorStep');
          }
        } else {
          setShowDeliveryOptions(true);
        }

        //Ioss information
        if (destinationCountry.iossApplies) {
          const iossValueLimits = response.data.find((x) => x.iossApplies === true);
          iossValueLimits ? props.setIossLimits(iossValueLimits) : props.setIossLimits(null);
        } else {
          props.setIossLimits(null);
        }
      })
      .catch((err) => {
        console.log(err);
        // console.log('Failed to load');
      });
  }, 500);

  useEffect(() => {
    if (destinationCountry && destinationCountry?.code2Digit === 'GB') {
      debounceCountryFeature();
      setFieldTouched('noOfItems', true);
    }
  }, [destinationCountry, formikValues?.createPalletShipment, isAmendShipment,postalCode,formikValues.noOfItems]);
  
  useEffect(() => {
    if (destinationCountry && destinationCountry?.code2Digit === 'GB' && formikValues.weight > 0) {
      debounceCountryFeature();
      setFieldTouched('noOfItems', true);
      setFieldTouched('weight', true);
    }
  }, [postalCode]);
  // }, [destinationCountry, formikValues.noOfItems]);

  useEffect(() => {
    if (destinationCountry && destinationCountry?.code2Digit !== 'GB') {
      debounceCountryFeature();
      setFieldTouched('noOfItems', true);
    }
  }, [destinationCountry, formikValues?.accountNumber]);

  const isDriverCollection = () => {
    return (props.formikValues || {}).handOverMethod === 'driverCollection';
  };

  useEffect(() => {
    if ((loadedFrom || {}).loadedFrom && isDomestic) {
      resetLengthWidthHeight();
    }
  }, [destinationCountry]);

  const handleModal = (value) => {
    setOpen(value);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const rowClicked = (val) => {
    setIsAddressSelected(true);
    const data = `${val.address.address1 || ''} ${val.address.address2 || ''} ${val.address.postalTown || ''}`;
    props.setServicePointData(data);
    setFieldValue('servicePoint', data);
    handleModal(false);
  };

  const getServicePoints = () => {
    setservicePoints([]);
    props.loader(true);
    const { servicePoint } = props.formikValues;
    const encodePostcodeURI = encodeURIComponent(servicePoint);
    const url = `${ServiceEndPoints.getServicePoints}?countryCode=GB&PostCode=${encodePostcodeURI}&ServicePointType=parcelshop`;
    axiosConfig
      .get(url)
      .then((res) => {
        setservicePoints(res.data.slice(0, NoOfRows));
        handleModal(true);
      })
      .catch((err) => {
        setservicePoints([]);
        props.setSnackBar({ type: 'error', msg: 'No service point found' });
      })
      .finally(() => {
        props.loader(false);
      });
  };

  const saveDomesticDimensions = () => {
    setIsDialogOpen(false);
    resetLengthWidthHeight();
  };

  const handleDialogClose = () => {
    resetLengthWidthHeight();
    setIsDialogOpen(false);
    setFieldValue('parcelDetails', oldParcelDetails);
  };

  const resetLengthWidthHeight = () => {
    setFieldValue('length', '');
    setFieldValue('width', '');
    setFieldValue('height', '');
    setFieldValue('pieceWeight', 0);

    setFieldTouched('length', false);
    setFieldTouched('width', false);
    setFieldTouched('height', false);
    setFieldTouched('pieceWeight', false);
  };

  useEffect(() => {
    setVolumetricWeight();
  }, [formikValues.length, formikValues.width, formikValues.height, formikValues.parcelDetails, isDomestic, volumetricWeightDivisor]);

  const setVolumetricWeight = () => {
    let volumetricWeight = 0;
    let totalpieceWeight = 0;
    if (isDomestic && (formikValues.parcelDetails || []).length > 0) {
      formikValues.parcelDetails.forEach((row) => {
        volumetricWeight += (row.length * row.width * row.height) / volumetricWeightDivisor;
        totalpieceWeight += parseFloat(row.pieceWeight);
      });
    } else if (formikValues.length && formikValues.width && formikValues.height) {
      volumetricWeight = (formikValues.length * formikValues.width * formikValues.height) / volumetricWeightDivisor;
    }
    setFieldValue('totalVolumetricWeight', parseFloat(volumetricWeight).toFixed(2));
    setFieldValue('totalpieceWeight', parseFloat(totalpieceWeight).toFixed(2));
  };

  useEffect(() => {
    if (formikValues.servicePoint === '') {
      setIsAddressSelected(false);
    }
  }, [formikValues.servicePoint]);
  useEffect(() => {
    if (formikValues.handOverMethod === 'servicePoint') {
      setIsAddressSelected(false);
    }
  }, [formikValues.handOverMethod]);

  useEffect(() => {
    if (!(isAmendShipment && formikValues.addressType === "residential") &&
      formikValues?.destinationCountry?.code === "GBR" && !isOrderDefaults &&
      formikValues.weight && formikValues.noOfItems && dimsValidation.maxPallets &&
      ((formikValues.weight / formikValues.noOfItems) > dimsValidation.maxItemWeight) &&
      (!isAmendShipment || (isAmendShipment &&
        formikValues.deliveryType === "deliverToDoorStep" && isDriverCollection()))) {
      formikValues.createPalletShipment !== "yes" && setIPalletsAutoSwitched(true);
      setFieldValue("createPalletShipment", "yes");
    }
    else {
      setIPalletsAutoSwitched(false);
    }
  }, [formikValues.noOfItems, formikValues.weight, formikValues?.destinationCountry]);

  useEffect(() =>{
   if(isOrderDefaults && destinationCountry.code2Digit === 'GB')
   {
    weight.validation.validations = weight.validation.validations.filter(
      (x) => x.type !== 'max',
    );
    weight.validation.validations.push({
         type: 'max',
         params: [
           dimsValidation.maxItemWeight * formikValues.noOfItems,
           `Declared weight must be less than or equal to ${dimsValidation.maxItemWeight} per parcel.`
         ],
       });
       props.updateValidationSchema?.();
   }
  },[formikValues.noOfItems, dimsValidation.maxItemWeight])

  //Pallet Changes
  useEffect(() => {
    // if(isOrderDefaults)
    // {
    // if (formikValues.createPalletShipment === 'yes' && isOrderDefaults) {
    //   setFieldValue('pallets', 1)
    // }
    // }
    // else{
    if (formikValues?.createPalletShipment === 'yes' && (isDomestic || destinationCountry?.code2Digit === 'GB')) {
      if (!(loadedFrom?.loadedFrom)) {
        setFieldValue('pallets', 1);
        // setFieldValue('noOfItems', 1); moved to useEffect of pallets
      }
      setFieldValue('addParcelDimensions', false);
      setShowPalletField(true);
      setShowDimension(false);
    }
    else {
      if (!(loadedFrom?.loadedFrom) && !isOrderDefaults) {
        setFieldValue('noOfItems', 1);
      }
      setFieldValue('pallets', 0);
      setShowPalletField(false);
      setShowDimension(true);
      setIPalletsAutoSwitched(false);
    }
    // }
  }, [formikValues?.createPalletShipment, destinationCountry]);

  useEffect(() => {
    if (formikValues?.createPalletShipment === 'yes') {
      setFieldValue('noOfItems', formikValues?.pallets);
      if(setErrors && formikValues?.pallets === 0){
        setTimeout(() => {
          errors.pallets = `Must be a value between 1 to ${dimsValidation.maxPallets}`;
          setErrors?.(errors);
        }, 100); 
      } 
    }
  }, [formikValues?.pallets])

  //Method to re-calculate the volumetric weight once the account changes
  useEffect(() => {
    const { parcelDetails } = formikValues;
    if (isDomestic && parcelDetails.length > 0) {
      const updatedParcelDetails = parcelDetails.map((rows) => {
        rows.volumetricWeight = (rows.length * rows.width * rows.height) / volumetricWeightDivisor;
        return rows;
      });
      setFieldValue('parcelDetails', updatedParcelDetails);
    }
  }, [volumetricWeightDivisor]);


  useEffect(() => {
    if (formikValues?.postalCode?.substring(0, 2) == 'BT' && formikValues?.createPalletShipment == 'yes') { 
      setFieldValue("createPalletShipment", "no");
    }

  }, [formikValues.postalCode,formikValues.createPalletShipment])


//  console.log(formikValues?.postalCode)
// console.log(formikValues.postalCode)
  return (
    <Grid container direction="row" alignItems="flex-start" spacing={6} className="parcel-main-grid">
      <Grid
        container
        spacing={2}
        xs={12}
        sm={6}
        lg={6}
        style={isDomestic ? { padding: '18px 24px 18px 20px' } : { padding: '24px 24px 24px 20px' }}
      >
        {isDriverCollection() || isTemplate || isAddressBook || isOrderDefaults ? null : (
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
          >
            <FormRenderer
              isAddressSelected={isAddressSelected}
              {...servicePoint.props}
              endArguments={{
                endAdornment: (
                  <IconButton
                    className="Parcel-Search-Icon-btn"
                    disabled={errors.servicePoint || isAddressSelected}
                    onClick={getServicePoints}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              fullWidth
            />
            <CustomDialog
              className="Select-service-dialog"
              open={open}
              onClose={handleClose}
              title="Select Service Point"
            >
              <CustomDataTable rows={servicePoints} rowClicked={rowClicked} />
            </CustomDialog>
          </Grid>
        )}
        {(isDriverCollection() || isAddressBook || isTemplate || isOrderDefaults) &&
          (!showPalletField || !isDomestic) ? (
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
          >
            <FormRenderer {...noOfItems.props} isDisabled={isAmendShipment && (formikValues.deliveryType !== "deliverToDoorStep" || formikValues?.inboxReturnLabel === "true")} fullWidth />
          </Grid>
        ) : null}

        {isDomestic ? (
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
          >
            <FormRenderer {...weight.props} fullWidth />
            {isPalletsAutoSwitched && (
              <span className="span-normal">Maximum parcel weight exceeded. Please send as a pallet.</span>
            )}
          </Grid>
        ) : (
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
          >
            <FormRenderer {...weight.props} restrictToOneDecimal={!isDomestic} fullWidth />
          </Grid>
        )}
        {/* {(isTemplate || isOrderDefaults || (isAddressBook && isDomestic) || (isDomestic && isDriverCollection())) &&
        pallets ? (
          <Grid item xs={4} md={4} lg={4}>
            <FormRenderer {...pallets.props} fullWidth />
          </Grid>
        ) : null} */}
      </Grid>

      {(isDomestic || isOrderDefaults) && <Grid
        container
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        spacing={1}
        className="main-Add-Parcel"
        style={!isDomestic ? { maxWidth: '51%', paddingRight: '10px', paddingTop: '24px' } : null}
      >
        {isDomestic ? (
          showDimension ? (
            <React.Fragment>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                lg={5}
                className="main-Add-parcelDimensions"
                style={{ marginLeft: '2%' }}
              >
                <FormRenderer {...addParcelDimensions.props} fullWidth />
              </Grid>
            </React.Fragment>
          ) : null
        ) : (
          isOrderDefaults && (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '9px' }}>
              <AddDimension
                isRequired={!isAddressBook && !isOrderDefaults}
                isDomestic={false}
                setFieldValue={setFieldValue}
              />
            </Grid>
          )
        )}
        {isDomestic && props.formikValues.addParcelDimensions && (
          <React.Fragment>
            {/* <Grid item sm={1} md={1} lg={1}></Grid> */}
            {/* <Grid item sm={1} md={1} lg={1}></Grid> */}
            <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginLeft: '10px' }}>
              <div className="volumetict-wt-container" style={{ display: 'flex' }}>
                <span className="volumetricWt">Total volumetric wt.(kg):</span>&nbsp;
                {formikValues.totalVolumetricWeight}
              </div>
            </Grid>
            {/* <Grid item sm={1} md={1} lg={1} style={{ marginLeft: '75px' }}></Grid> */}
            <Grid item xs={12} sm={12} md={1} lg={1} className="parcel-details-edit-btn">
              <IconButton onClick={() => setDialogOpen()} style={{ height: '30px', width: '30px', marginLeft: '1%' }}>
                <SvgIcon component={EditIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem' }} />
              </IconButton>
            </Grid>
          </React.Fragment>
        )}
      </Grid>}
      {!isDomestic && (
        <React.Fragment>
          {(
            <Grid  container
            item
            {...(isOrderDefaults?{justify:"flex-end",style: { marginTop: '-2.2%' } }:{
              xs:12,
              sm:6,
              md:6,
              lg:6,
              spacing:1,
              style:{ paddingRight:'0px',justifyContent:'flex-end'}
            })}          
            >
              {isOrderDefaults ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justify="flex-start" spacing={1} style={{ marginBottom: '-3.4%' }}>
                    <Grid item>
                      <span className="volumetricWt">Weight, Length, Width and Height are applicable on parcel level.</span>
                    </Grid>
                  </Grid>
                </Grid>
              ) : ' '}
              {( isDriverCollection() || isAddressBook)?(<Grid
                 item
                 xs={12}
                 sm={6}
                 md={5}
                 lg={5}
                // className="main-Add-parcelDimensions"
                style={{ marginTop: '1%' }}
              >
                <FormRenderer {...duplicateParcelDimensions.props} fullWidth />
              </Grid>):null}
              <Grid  item 
              xs={12}
              {...isOrderDefaults?{
                sm:12 ,md:3,lg:3
              }:{
                sm:6,
                md:5,
                lg:5, style:{ marginTop: '2%', marginLeft: '11%'  }
              }}
               >
                <Grid container justify="flex-end" spacing={1}>
                  <Grid item>
                    <span className="volumetricWt">Total volumetric wt.(kg):</span>
                  </Grid>
                  <Grid item>
                    <span>{!isOrderDefaults ? totalValues.totalVolumetricWeight?.toFixed(2) : formikValues.totalVolumetricWeight} </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isOrderDefaults && (
            <Grid container item justify="flex-end" style={{ marginTop: '-20px' }}>
              <InternationalParcelDetails
                maxParcels={dimsValidation.maxParcels}
                totalValues={totalValues}
                setTotalValues={setTotalValues}
                parcelCount={formikValues.noOfItems > dimsValidation.maxParcels ? dimsValidation.maxParcels + 1 :formikValues.noOfItems}
                setFieldValue={setFieldValue}
                formValues={formikValues}
                dimsValidation={intlDimsValidation}
                volumetricDivisor={volumetricWeightDivisor}
                isAddressBook={props.isAddressBook}
                loadedFrom={loadedFrom}
                setAfterSubmitFlag={setAfterSubmitFlag}
                afterSubmitFlag={afterSubmitFlag}
              />
            </Grid>
          )}
        </React.Fragment>
      )}
      {isDomestic && !isOrderDefaults && (
        <>
        <Grid container item>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormRenderer {...createPalletShipment.props} data={createPalletOptions} fullWidth isDisabled={formikValues?.postalCode?.substring(0, 2) == 'BT'||dimsValidation.maxPallets === '' || isAmendShipment && (formikValues.addressType === "residential" || formikValues.deliveryType !== "deliverToDoorStep" || !isDriverCollection())} disabled/>
          </Grid>
          {showPalletField && (
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <FormRenderer {...pallets.props} fullWidth />
            </Grid>
          )}
          
        </Grid>
        {formikValues?.postalCode?.substring(0, 2) == 'BT' && 
         <Grid container item style={{paddingTop:'0px'}}>
         <span class="span-required">Pallet service is not available for this postcode</span>
       </Grid>
        }
        </>
      )}

      <Grid container>
        <CustomModal open={isDialogOpen} title="Add parcel dimensions" onClose={handleDialogClose}>
          <AddDomesticDimension
            resetLengthWidthHeight={resetLengthWidthHeight}
            saveDimensions={saveDomesticDimensions}
            fromfeilds={props.formField}
            formikValues={props.formikValues}
            isDomestic={isDomestic}
            setFieldValue={setFieldValue}
            setOldParcelDetails={setOldParcelDetails}
            {...props}
          />
        </CustomModal>
      </Grid>
    </Grid>
  );
};

ParcelDetails.propTypes = {
  formField: PropTypes.object,
  setFieldValue: PropTypes.func,
  formikValues: PropTypes.object,
  errors: PropTypes.object,
  isDomestic: PropTypes.bool,
  isTemplate: PropTypes.bool,
  isOrderDefaults: PropTypes.bool,
  setShowDeliveryOptions: PropTypes.func,
};

ParcelDetails.defaultProps = {
  setShowDeliveryOptions: () => null,
};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBar: (data) => {
      dispatch(actions.setAppSnackBarData(data));
    },
    loader: (data) => {
      dispatch(actions.setLoaderLayOver(data));
    },
    setIossLimits: (data) => {
      dispatch(actions.setIossValueLimits(data));
    },
    setServicePointData: (data) => {
      dispatch(actions.setServiceLocationDetails(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParcelDetails);

const createPalletOptions = [
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'yes',
    label: 'Yes',
  },
];
