import { UK_POSTAL_CODE_REGEX } from '../../../constants/constants';
import { testFunctionForPostCodeRegex } from '../../../helperFunctions';

const domesticDefaultsForm = {
  formId: 'domesticDefaultsForm',
  formField: {
    destinationCountry: {
      props: {
        name: 'destinationCountry',
        label: 'Destination country',
        type: 'select',
        displayField: 'name',
        isRequired: true,
      },
      value: {
        code: 'GBR',
        code2Digit: 'GB',
        name: 'united kingdom',
        postcodeRegex: UK_POSTAL_CODE_REGEX,
      },
    },
    pallets: {
      props: {
        name: 'pallets',
        label: 'Pallets',
        type: 'number',
        inputProps: {
          min: 0,
          max: 1,
        },
      },
      validation: {
        name: 'pallets',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0, 'Pallets can  not be less than 0'],
          },
          {
            type: 'max',
            params: [1, 'Pallets can  not be greater than 1'],
          },
        ],
      },
    },
    addParcelDimensions: {
      props: {
        name: 'addParcelDimensions',
        label: 'Add parcel dimensions',
        type: 'checkbox',
      },
      value: false,
    },
    parcelDetails: {
      props: {
        name: 'parcelDetails',
        type: 'array',
        fields: {
          length: 'number',
          width: 'number',
          height: 'number',
          pieceWeight: 'number',
          volumetricWeight: 'number',
          id: 'number',
        },
      },
      values: [],
    },
    totalVolumetricWeight: {
      props: {
        name: 'totalVolumetricWeight',
      },
      value: 0.0,
    },
    weight: {
      props: {
        name: 'weight',
        label: 'Weight (kg)',
        type: 'number',
        placeholder: 'Select',
        inputProps: {
          min: 0,
          max: 999,
        },
      },
      validation: {
        name: 'weight',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [1, 'Weight is outside permitted range'],
          },
          {
            type: 'max',
            params: [999, 'Weight is outside permitted range'],
          },
        ],
      },
    },
    length: {
      props: {
        name: 'length',
        label: 'Length (cm)',
        type: 'number',
        isRequired: true,
        inputProps: {
          min: 1,
          max: 200,
        },
      },
      validation: {
        name: 'length',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Length must be number type'],
          },
          {
            type: 'min',
            params: [1, 'Declared length must be greater than 0'],
          },
          {
            type: 'max',
            params: [200, 'Declared length must be less than or equal to 200'],
          },
          {
            type: 'when',
            params: [
              ['destinationCountry', 'width', 'height'],
              (destinationCountry, width, height, schema) => {
                if (destinationCountry) {
                  return destinationCountry.code !== 'GBR'
                    ? schema.required('Length is required.').test('', 'Length must be longest side', (value) => {
                      return value >= width && value >= height;
                    })
                    : schema.test('', 'Length must be longest side', (value) => {
                      return value >= width && value >= height;
                    });
                }
              },
            ],
          },
        ],
      },
    },
    width: {
      props: {
        name: 'width',
        label: 'Width (cm)',
        type: 'number',
        isRequired: true,
        inputProps: {
          min: 1,
          max: 120,
        },
      },
      value: 0,
      validation: {
        name: 'width',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Width must be number type'],
          },
          {
            type: 'min',
            params: [1, 'Declared width must be greater than 0'],
          },
          {
            type: 'max',
            params: [120, 'Declared width must be less than or equal to 120'],
          },
        ],
      },
    },
    height: {
      props: {
        name: 'height',
        label: 'Height (cm)',
        type: 'number',
        isRequired: true,
        inputProps: {
          min: 1,
          max: 120,
        },
      },
      value: 0,
      validation: {
        name: 'height',
        validationType: 'number',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Height is required.'],
          // },
          {
            type: 'typeError',
            params: ['Height must be number type'],
          },
          {
            type: 'min',
            params: [1, 'Declared height must be greater than 0'],
          },
          {
            type: 'max',
            params: [120, 'Declared height must be less than or equal to 120'],
          },
        ],
      },
    },

    deliveryType: {
      props: {
        name: 'deliveryType',
        label: 'Delivery type:',
        type: 'radio',
      },
    },
    doorStepInstructions: {
      props: {
        name: 'doorStepInstructions',
        label: 'Doorstep instructions',
        type: 'select',
        displayField: 'name',
      },
      value: '',
    },
    deliveryServicePoint: {
      props: {
        name: 'deliveryServicePoint',
        label: 'Find service point',
        type: 'text',
        placeholder: 'Find ServicePoint',
      },
      validation: {
        name: 'postalCode',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'deliveryType',
              (deliveryType, schema) => {
                return deliveryType === 'deliverToServicePoint'
                  ? schema.required('ServicePoint  is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (destinationCountry?.postcodeRegex) {
                  return testFunctionForPostCodeRegex(
                    schema,
                    'Please  enter a correct postal code',
                    destinationCountry?.postcodeRegex,
                  );
                }
              },
            ],
          },
        ],
      },
    },
    leaveSafeLocation: {
      props: {
        name: 'leaveSafeLocation',
        label: 'Leave safe location',
        type: 'text',
        placeholder: 'Leave Safe location',
      },
    },
    exchange: {
      props: {
        name: 'exchange',
        label: 'Exchange on Delivery:',
        type: 'radio',
      },
    },
    specialInstructions: {
      props: {
        name: 'specialInstructions',
        label: 'Special instructions',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      validation: {
        name: 'specialInstructions',
        validationType: 'string',
        validations: [{
          type: 'max',
          params: [30, 'Max 30 characters are allowed'],
        }],
      },
    },
    additionalInstruction: {
      props: {
        name: 'additionalInstruction',
        type: 'text',
        label: '',
        inputProps: {
          maxLength: 30,
        },
      },
    },
    product: {
      props: {
        name: 'product',
        label: 'Product',
        type: 'select',
        displayField: 'productNameForUI',
      },
    },
    service: {
      props: {
        name: 'service',
        label: 'Service',
        type: 'select',
        displayField: 'name',
      },
    },
    extendedCoverDomestic: {
      props: {
        name: 'extendedCoverDomestic',
        label: 'Extended liability',
        type: 'number',
        placeholder: 'Please select a level of extended liability, if required',
        inputProps: {
          min: 0,
          max: 5,
        },
      },
      validation: {
        name: 'extendedCoverDomestic',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0, 'Extended liability value should be between 0 - 5.'],
          },
          {
            type: 'max',
            params: [5, 'Extended liability value should be between 0 - 5.'],
          },
        ],
      },
    },

    inboxReturnLabel: {
      props: {
        name: 'inboxReturnLabel',
        label: 'Include inbox return label:',
        type: 'radio',
      },
    },

    returnReference: {
      props: {
        name: 'returnReference',
        label: 'Return reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      value: '',
    },

    noOfItems: {
      props: {
        name: 'noOfItems',
        label: 'No. of items',
        type: 'number',
        inputProps: {
          min: 1,
          max: 20,
        },
        restrictDecimal: true
      },
      validation: {
        name: 'noOfItems',
        validationType: 'number',
        validations: [
          {
            params: [1, 'Must be a value between 1 to 20'],
            type: 'min',
          },
          {
            type: 'max',
            params: [20, 'Must be a value between 1 to 20'],
          },
        ],
      },
    },
    defaultDomesticAccounts: {
      props: {
        name: 'defaultDomesticAccounts',
        label: 'Default domestic account',
        type: 'select',
        displayId: "accountId",
        displayField: 'accountName',
        hasDefaultValue: false,
      },
    },
    pieceWeight: {
      props: {
        name: 'pieceWeight',
        label: 'Weight (kg)',
        type: 'number',
        placeholder: 'Select',
        isRequired: true,
      },
      validation: {
        name: 'pieceWeight',
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Weight is required'],
          },
        ],
      },
    },
    totalpieceWeight: {
      props: {
        name: 'totalpieceWeight',
      },
      value: 0.0,
    },
    createPalletShipment: {
      props: {
        name: 'createPalletShipment',
        label: 'Create pallet shipment',
        type: 'radio',
      },
      value: 'no',
    },
    pallets: {
      props: {
        name: 'pallets',
        label: 'Pallets',
        type: 'number',
        // isDisabled:true,
        inputProps: {
          min: 0,
          max: 1,
        },
      },
      value: 1,

      validation: {
        name: 'pallets',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0, 'Pallets can  not be less than 0'],
          },
          {
            type: 'max',
            params: [1, 'Pallets can  not be greater than 1'],
          },
        ],
      },
    },
    shipmentMovement: {
      props: {
        name: 'shipmentMovement',
        label: 'Shipment Movement Type',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
      },
      validation: {
        validationType: 'object',
        validations: [
        ],
      },
    },
  clearanceTotalValue: {
      props: {
        name: 'clearanceTotalValue',
        label: 'Total Value',
        type: 'number',
      },
      validation: {
        name: 'clearanceTotalValue',
        validationType: 'number',
        validations: [
          {
            type: 'max',
            params: [99999999.99, 'Total Value be 99999999 or less'],
          },
        ],
      },
    },
    descriptionOfGoods: {
      props: {
        name: 'descriptionOfGoods',
        label: 'Description of goods(shipment level)',
        type: 'text',
      },
      validation: {
        name: 'descriptionOfGoods',
        validationType: 'string',
        validations: [
          // {
          //   type: 'matches',
          //   params: [/(.*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]){3}/i, 'Minimum 3 non blank characters are required'],
          // },
          {
            type: 'max',
            params: [80, 'Max 80 characters are allowed'],
          },
        ],
      },
    },
    goodsDescription: {
      props: {
        name: 'goodsDescription',
        label: 'Goods Description',
        type: 'text',
      },
      validation: {
        name: 'goodsDescription',
        validationType: 'string',
        validations: [
          {
            type: 'matches',
            params: [/(.*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]){3}/i, 'Minimum 3 non blank characters are required'],
          },
        ],
      },
    },
    shippingCharges: {
      props: {
        name: 'shippingCharges',
        label: 'Shipping Charges',
        type: 'number',
        inputProps: {
          maxLength: 8,
        },
      },
      validation: {
        name: 'shippingCharges',
        validationType: 'number',
        validations: [
          {
            type: 'max',
            params: [99999999.99, 'Total Value be 99999999 or less'],
          },
        ],
      },
    },
    reasonExport: {
      props: {
        name: 'reasonExport',
        label: 'Reason For Export',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
      },
      validation: {
        validationType: 'object',
      },
    },

      commodityCode: {
          props: {
            name: 'commodityCode',
            label: 'Commodity code',
            type: 'text',
            inputProps: {
              maxLength: 10,
            },
            class_Name: 'commodity_Code',
          },
          validation: {
            validationType: 'string',
            validations: [
              {
                type: 'matches',
                params: [/^[0-9]{10}$/, 'Commodity code should be a 10 digit number'],
              },
              {
                type: 'max',
                params: [10, 'Commodity code should be a 10 digit number'],
              },
            ],
          },
        },
        quantity: {
          props: {
            name: 'quantity',
            label: 'UnitQuantity',
            type: 'number',
            inputProps: {
              max: 999,
              min: 1,
            },
          },
          validation: {
            validationType: 'number',
            name: 'quantity',
            validations: [
              {
                type: 'max',
                params: [999, 'Quantity must be 999 or less'],
              },
              {
                type: 'integer',
                params: ['Quantity should be a whole number'],
              },
            ],
          },
        },
        unitWeight: {
          props: {
            name: 'unitWeight',
            label: 'UnitWeight',
            type: 'number',
            inputProps: {
              max: 999,
              min: 1,
            },
          },
          validation: {
            validationType: 'number',
            name: 'unitWeight',
            validations: [
              {
                type: 'max',
                params: [999, 'unitWeight must be 999 or less'],
              },
              {
                type: 'integer',
                params: ['unitWeight should be a whole number'],
              },
            ],
          },
        },
        unitValue: {
          props: {
            name: 'unitValue',
            label: 'UnitValue',
            type: 'number',
            inputProps: {
              max: 999,
              min: 1,
            },
          },
          validation: {
            validationType: 'number',
            name: 'unitValue',
            validations: [
              {
                type: 'max',
                params: [999, 'unitValue must be 999 or less'],
              },
              {
                type: 'integer',
                params: ['unitValue should be a whole number'],
              },
            ],
          },
        },
        countryOfOrigin: {
          props: {
            name: 'countryOfOrigin',
            label: 'Country of origin',
            type: 'select',
            displayField: 'name',
          },
          validation: {
            validationType: 'object',
            name: 'countryOfOrigin',
            // validations: [
            //   {
            //     type: 'required',
            //     params: ['Country of origin is required'],
            //   },
    
            //   {
            //     type: 'max',
            //     params: [2, 'Maximum of 2 characters allowed'],
            //   },
            //   {
            //     type: 'min',
            //     params: [2, 'Minimum of 2 characters allowed'],
            //   },
            // ],
          },
    },
    clearanceItemData: {
      props: {
        name: 'clearanceItemData',
        type: 'array',
        fields: {
          commodityCode: 'text',
          fullDescriptionOfGoods: 'text',
          weight: 'number',
          quantity: 'number',
          unitValue: 'number',
          countryOfOrigin: 'text',
        },
      },
      values: [],
      validation: {
        name: 'clearanceItemData',
        validationType: 'array',
        validations: [],
      },
    },
  },
};

export default domesticDefaultsForm;
