import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { getYupSchemaFromMetaData } from '../../../../../utils/yupSchema/yupSchemaGenerator';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import './addClearanceDefault.scss';
import InputButton from '../../../../../framework/Inputs/inputButton/inputButton';
import { GET } from '../../../../../api/axios';
import { ReactComponent as InfoIcon } from '../../../../../assets/images/InfoIcon.svg';
import { dynamicSort } from '../../../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';

const AddClearanceItemDefault = (props) => {
  const {
    addNewCommodityDetails,
    updateCommodityDetails,
    data: { operationType, rowDetails, index, totalWeight },
  } = props;

  const countryDetails = { ...(rowDetails || {}).countryOfOrigin };
  const formField = getFormField(totalWeight);

  const validationSchema = getYupSchemaFromMetaData(formField, [], [], [['quantity', 'weight']]);
  const emptyValues = {
    commodityCode: '',
    fullDescriptionOfGoods: '',
    weight: '',
    quantity: '',
    unitValue: '',
    countryOfOrigin: '',
    skuNumber: '',
    marketplaceChannel: '',
  };

  const [countries, setCountries] = useState([]);
  const [disblebtn, setdisblebtn] = useState(false);
  const [initialValues, setInitialValues] = useState(
    operationType === 'updateCommodityDetails' ? rowDetails : emptyValues,
  );

  const formikRef = useRef(null);

  const handleSubmit = async (values, actions) => {
    if (operationType === 'updateCommodityDetails') {
      updateCommodityDetails(index, values);
    } else {
      addNewCommodityDetails(values);
    }
  };

  const getCountry = (countryCode) => {
    return countries.find((country) => country.code === countryCode);
  };

  useEffect(() => {
    if (operationType === 'updateCommodityDetails') {
      let existingValues = { ...rowDetails };
      existingValues['countryOfOrigin'] = getCountry((countryDetails || {}).code);
      setInitialValues(existingValues);
    } else {
      emptyValues['countryOfOrigin'] = getCountry('GBR');
      setInitialValues(emptyValues);
    }
  }, [countries, operationType, rowDetails]);

  useEffect(() => {
    // eslint-disable-next-line new-cap
    GET(ServiceEndPoints.getCountries)
      .then((res) => {
        setCountries(res.data.countries.sort(dynamicSort('name')));
      })
      .catch((err) => {
        setCountries([]);
      });
  }, []);

  const resetbtnForm = async (values,errors) => {
      await setdisblebtn(true);
      handleSubmit(values);

  }
  const resetCommodityForm = (resetForm, setFieldValue) => {
    resetForm();
    setFieldValue('countryOfOrigin', getCountry('GBR'));
  };

  return (
    <div className="wd-100">
      <Grid container>
        <Formik
          initialValues={initialValues}
          // onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnMount={true}
          innerRef={formikRef}
        >
          {({ values, setFieldValue, errors, resetForm, dirty }) => (
            <Form className="wd-100">
              <Grid container spacing={2} direction="column" style={{ paddingTop: '12px' }}>
                <Grid item>
                  <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item xs={11} sm={11}>
                      <FormRenderer
                        {...formField.commodityCode.props}
                        // onChange={(e) => {
                        //   setFieldValue('commodityCode', e.target.value);
                        //   e.target.value.trim() && debounced(e.target.value);
                        // }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <Tooltip title="Use the info button to look this up at gov.uk">
                        <IconButton target="_blank" href="https://www.trade-tariff.service.gov.uk/sections">
                          <SvgIcon fontSize="large" component={InfoIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <FormRenderer {...formField.fullDescriptionOfGoods.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...formField.weight.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...formField.quantity.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...formField.unitValue.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...formField.countryOfOrigin.props} data={countries} fullWidth />
                </Grid>
                <Grid item>
                  <Grid spacing={3} container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                      <InputButton
                        variant="outlined"
                        className="outlined-btn"
                        onClick={() => resetCommodityForm(resetForm, setFieldValue)}
                        label="Reset"
                        buttonType="reset"
                        isDisabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <InputButton
                        variant="contained"
                        className="contained-btn"
                        buttonType="submit"
                        label={operationType === 'updateCommodityDetails' ? 'Update' : 'Add'}
                        isDisabled={disblebtn}
                        onClick={() => resetbtnForm(values,errors)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </div>
  );
};


export default AddClearanceItemDefault;

const getFormField = (totalWeight) => {
  return {
    commodityCode: {
      props: {
        name: 'commodityCode',
        label: 'Commodity code',
        type: 'text',
        class_Name: 'commodity_Code',
        inputProps: {
          maxLength: 8,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'matches',
            params: [/^[0-9]{8}$/, 'Commodity code should be a 8 digit number'],
          },
          {
            type: 'max',
            params: [8, 'Commodity code should be a 8 digit number'],
          },
          
        ],
      },
    },
    fullDescriptionOfGoods: {
      props: {
        name: 'fullDescriptionOfGoods',
        label: 'Description of goods',
        type: 'text',
        inputProps: {
          maxLength: 50,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [50, 'Maximum of 50  characters are allowed'],
          },

        ],
      },
    },
    weight: {
      props: {
        name: 'weight',
        label: 'Net weight (kg)',
        type: 'number',
        inputProps: {
          min: 0,
          max: 999,
          step: 'any',
        },
      },
      validation: {
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0.01, 'Weight must be greater than 0'],
          },
          {
            type: 'max',
            params: [999, 'Weight must be 999 or less'],
          },
        ],
      },
    },
    quantity: {
      props: {
        name: 'quantity',
        label: 'Quantity',
        type: 'number',
        inputProps: {
          max: 999,
          min: 1,
        },
      },
      validation: {
        validationType: 'number',
        name: 'quantity',
        validations: [

          {
            type: 'min',
            params: [1, 'Quantity must be greater than or equals to 1'],
          },
          {
            type: 'max',
            params: [999, 'Quantity must be 999 or less'],
          },
        ],
      },
    },
    unitValue: {
      props: {
        name: 'unitValue',
        label: 'Unit value',
        type: 'number',
        inputProps: {
          min: 0,
          max: 1000000,
          step: 'any',
        },
      },
      validation: {
        validationType: 'number',
        name: 'unitValue',
        validations: [
          {
            type: 'max',
            params: [10000, 'Unit value must be 10000 or less'],
          },
          {
            type: 'min',
            params: [0.01, 'Unit value must be greater than 0'],
          },
          // {
          //   type: 'integer',
          //   params: ['Unit Value should be a whole number'],
          // },
        ],
      },
    },
    countryOfOrigin: {
      props: {
        name: 'countryOfOrigin',
        label: 'Country of origin',
        type: 'select',
        displayField: 'name',
      },
      validation: {
        validationType: 'object',
        name: 'countryOfOrigin',
        validations: [


          {
            type: 'max',
            params: [2, 'Maximum of 2 characters allowed'],
          },
          {
            type: 'min',
            params: [2, 'Minimum of 2 characters allowed'],
          },
        ],
      },
    },
  };
};
