import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { abortOnGoingCallsFor, axiosConfig } from '../../api/axios';
import { useSelector } from 'react-redux';

import FormRenderer from '../../framework/Inputs/formRenderer/formRenderer';
import { sortBasedOnNestedOrNot } from '../../utils/helperFunctions';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';

const useStyles = makeStyles({
  paperOpacity: {
    opacity: 0.5,
  },
  disableSpan: {
    opacity: '0.5 !important',
  },
  nonDisableSpan: {
    color: '#000 !important',
  },
});
export const AccountFromDetails = (props) => {
  const classes = useStyles();

  const {
    formField: {
      selectAccount,
      accountNumber,
      tradingAddress,
      invoiceAddress,
      postalCode,
      companyName,
      // termsOfDelivery,
      returnAddress,
      linkedReturnAccountNumber,
      fdaRegistrationNumber,
      eoriNumber,
      vatORtvaNumber,
      vatRegistersCheckbox,
      vatRadioBox,
      iossNumber,
      ukimsNumber,
      defermentAccountNumber
    },
    formikValues,
    setFieldValue,
    accounts,
  } = props;

  const [maintainer, setMaintainer] = useState([]);
  let userAccounts = useSelector((state) => state.accounts.userAccounts);
  const [isVatRegister, setVatRegister] = useState(false);
  const [isNotVatRegister, setNotVatRegister] = useState(false);
  const [isOutSideUK, setoutSideUK] = useState(false);

  const defaultFields = () => {
    setFieldValue('accountNumber', '');
    setFieldValue('tradingAddress', '');
    setFieldValue('invoiceAddress', '');
    setFieldValue('postalCode', '');
    setFieldValue('companyName', '');
    // setFieldValue('termsOfDelivery', '');
    setFieldValue('returnAddress', '');
    setFieldValue('linkedReturnAccountNumber', '');
    setFieldValue('fdaRegistrationNumber', '');
    setFieldValue('eoriNumber', '');
    setFieldValue('vatORtvaNumber', '');
    setFieldValue('iossNumber', '');
    setFieldValue('ukimsNumber', '');
    setFieldValue('defermentAccountNumber', '');
    setVatRegister(false);
    setoutSideUK(false);
    setNotVatRegister(false);
  };

  const getAccountdetails = (id) => {
    defaultFields();
    abortOnGoingCallsFor(ServiceEndPoints.getShipConfiguration);
    axiosConfig.get(`${ServiceEndPoints.getShipConfiguration}?accountIds=${id}`)
      .then((res) => {
        setMaintainer(res.data);
        setFieldValue('accountNumber', res.data[0].accountId);
        setFieldValue(
          'tradingAddress',
          res.data[0].sender === null
            ? ''
            : res.data[0].sender?.address1?.concat(
              ', ',
              res.data[0].sender?.address2,
              ', ',
              res.data[0].sender?.address3,
              '\n',
              res.data[0].sender?.city,
              res.data[0].sender?.state === null || res.data[0].sender?.state === "" ? '' : ', ',
              res.data[0].sender?.state === null || res.data[0].sender?.state === "" ? '' : res.data[0].sender?.state,
              '\n',
              res.data[0].sender?.postalCode,
              '\n',
              res.data[0].sender?.country,
            ),
        );
        setFieldValue(
          'invoiceAddress',
          res.data[0].invoiceAddress === null
            ? ''
            : res.data[0].invoiceAddress?.address1?.concat(
              ', ',
              res.data[0].invoiceAddress?.address2,
              ', ',
              res.data[0].invoiceAddress?.address3,
              '\n',
              res.data[0].invoiceAddress?.address4,
              res.data[0].invoiceAddress?.address5 === null || res.data[0].invoiceAddress?.address5 === "" ? '' : ', ',
              res.data[0].invoiceAddress?.address5 === null || res.data[0].invoiceAddress?.address5 === "" ? '' : res.data[0].invoiceAddress?.address5,
              '\n',
              res.data[0].invoiceAddress?.postalCode,
              '\n',
              res.data[0].invoiceAddress?.telephoneNumber,
            ),
        );
        setFieldValue('postalCode', res.data[0].sender === null ? '' : res.data[0].sender?.postalCode);
        setFieldValue('companyName', res.data[0].sender === null ? '' : res.data[0].sender?.companyName);
        // setFieldValue(
        //   'termsOfDelivery',
        //   res.data[0].customsRegistration === null ? '' : res.data[0].customsRegistration?.termsOfDelivery,
        // );
        setFieldValue(
          'returnAddress',
          !(res?.data[0]?.returnConfiguration)
            ? ''
            : res.data[0].returnConfiguration.returnRecipient?.address1?.concat(
              ',',
              res.data[0].returnConfiguration.returnRecipient?.address2,
              ', ',
              res.data[0].returnConfiguration.returnRecipient?.address3,
              '\n',
              res.data[0].returnConfiguration.returnRecipient?.city,
              ', ',
              res.data[0].returnConfiguration.returnRecipient?.state,
              '\n',
              res.data[0].returnConfiguration.returnRecipient?.postalCode,
              '\n',
              res.data[0].returnConfiguration.returnRecipient?.country,
            ),
        );
        setFieldValue(
          'linkedReturnAccountNumber',
          res.data[0].returnConfiguration === null ? '' : res.data[0].returnConfiguration?.customerReturnAccount,
        );
        setFieldValue(
          'fdaRegistrationNumber',
          res.data[0].customsRegistration === null ? '' : res.data[0].customsRegistration?.fdaNumber,
        );
        setFieldValue(
          'eoriNumber',
          res.data[0].customsRegistration === null ? '' : res.data[0].customsRegistration?.eoriNumber,
        );
        setFieldValue(
          'vatORtvaNumber',
          res.data[0].customsRegistration === null ? '' : res.data[0].customsRegistration?.vatTvaNumber,
        );
        // setFieldValue('vatRegistersCheckbox',res.data[0].customsRegistration.vatRegisteredOutsideUK)
        // setFieldValue('vatRadioBox',res.data[0].customsRegistration.vatRegistered)
        setVatRegister(res.data[0].customsRegistration?.vatRegistered);
        setoutSideUK(res.data[0].customsRegistration?.vatRegisteredOutsideUK);
        setNotVatRegister(!res.data[0].customsRegistration?.vatRegistered);
        // if (isNotVatRegister) {
        //   setFieldValue('vatORtvaNumber', '');
        // }
        setFieldValue('iossNumber',
          res.data[0].customsRegistration === null ? '' : res.data[0].customsRegistration?.iossNumber);

          setFieldValue('ukimsNumber',
            res.data[0].customsRegistration?.ukimsNumber === null ? '' : res.data[0].customsRegistration?.ukimsNumber);
        setFieldValue('defermentAccountNumber',
        res.data[0].customsRegistration?.deferredAccountNumber === null ? '' : res.data[0].customsRegistration?.defermentAccountNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setFieldValue('selectAccount', accounts[0]);
    defaultFields();
  }, [accounts]);

  useEffect(() => {
    if (formikValues.selectAccount?.accountId) {
      getAccountdetails(formikValues.selectAccount.accountId);
    }
    if (!formikValues.selectAccount && accounts?.length > 0) {
      setFieldValue('selectAccount', accounts[0]);
    }
  }, [formikValues.selectAccount]);

  return (
    <div className="wd-100">
      <Grid item xs={12} sm={5} md={5} className="sel-accout-container">
        <Grid container spacing={2} direction="column" style={{ paddingRight: '10px' }}>
          <Grid item>
            <FormRenderer 
            {...selectAccount.props} 
            data={accounts} 
            fieldValue={formikValues.selectAccount}
            valuePropertyName="accountId" 
            fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        spacing={2}
        direction="row"
        alignItems="flex-start"
        className={
          formikValues.selectAccount === undefined || formikValues.selectAccount.value === ''
            ? classes.paperOpacity
            : ''
        }
      >
        <Grid item lg={5} md={5} sm={12}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <FormRenderer {...accountNumber.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...companyName.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...tradingAddress.props} isMultilined="true" noOfRows="4" fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...postalCode.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...invoiceAddress.props} isMultilined="true" noOfRows="4" fullWidth isDisabled={true} />
            </Grid>
          </Grid>
        </Grid>

        <Divider orientation="vertical" flexItem style={{ marginRight: '10px', marginLeft: '10px' }} />

        <Grid item lg={5} md={5} sm={12}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              {/* <FormRenderer {...vatRadioBox.props} fullWidth /> */}
              <Typography component="div" display="block">
                VAT / TVA:{' '}
                <Typography
                  component="span"
                  style={{ paddingLeft: '5px', paddingRight: '7px', color: 'red' }}
                  className={isVatRegister ? classes.nonDisableSpan : classes.disableSpan}
                >
                  VAT registered
                </Typography>{' '}
                <Typography
                  component="span"
                  style={{ paddingLeft: '5px', paddingRight: '5px', color: 'red' }}
                  className={isNotVatRegister ? classes.nonDisableSpan : classes.disableSpan}
                >
                  Not VAT registered
                </Typography>
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="row" justify="space-around" alignItems="flex-start">
                <Grid item xs={6}>
                  <FormRenderer {...vatORtvaNumber.props} fullWidth isDisabled={true} />
                </Grid>
                <Grid item xs={6}>
                  {/* <FormRenderer {...vatRegistersCheckbox.props} fullWidth/> */}
                  <Typography component="p" style={{ color: '#000000E6', fontStyle: 'italic' }} hidden={!isOutSideUK}>
                    (VAT registered outside of the UK)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormRenderer {...iossNumber.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...eoriNumber.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...ukimsNumber.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...defermentAccountNumber.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...fdaRegistrationNumber.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...linkedReturnAccountNumber.props} fullWidth isDisabled={true} />
            </Grid>
            <Grid item>
              <FormRenderer {...returnAddress.props} fullWidth isDisabled={true} multiline={4} />
            </Grid>
            {/* <Grid item>
              <FormRenderer {...termsOfDelivery.props} fullWidth isDisabled={true} />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
