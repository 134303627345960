/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { connect } from 'react-redux';
import { shipmentMovementOptions ,reasonOptions } from '../../../../utils/constants/constants';
import ClearanceItemDetails from './clearanceItemDetails/clearanceItemDetails';

const DomesticDecleration = (props) => {
  const {
    formField:{
        shipmentMovement,
        clearanceTotalValue,
        descriptionOfGoods,
        shippingCharges,
        reasonExport,
        goodsDescription,
        commodityCode,
        quantity,
        unitValue,
        unitWeight,
        countryOfOrigin
    },
    isCarriageForward,
    countries,

  } = props;

  return (

<div className="wd-100">
<Grid container spacing={3} direction="row" alignItems="flex-start" style={{ paddingTop: '0px' }}>
  <Grid item xs={12} sm={4} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '38px' }}>
    <FormRenderer {...shipmentMovement.props} fullWidth data={shipmentMovementOptions} />
  </Grid>
</Grid>
<Grid container spacing={3} direction="row" style={{ paddingTop: '4px' }} alignItems="flex-start">

    <Grid item xs={12} sm={12} md={6} style={{ paddingRight: '37px' }}>
      <FormRenderer {...descriptionOfGoods.props} fullWidth />
    </Grid>
  
  <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
    <FormRenderer {...clearanceTotalValue.props} fullWidth />
  </Grid>

</Grid>
  <Grid item xs={12} sm={12} md={12} lg={12}>
    <ClearanceItemDetails {...props} />
  </Grid>
    <Grid container spacing={3} direction="row" style={{ paddingTop: '20px' }} alignItems="flex-start">
      <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
        <FormRenderer {...shippingCharges.props} fullWidth />
      </Grid>
      <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
        <FormRenderer {...reasonExport.props} data={reasonOptions} fullWidth />
      </Grid>
    </Grid>



</div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
  };
};

export default connect(mapStateToProps, null)(DomesticDecleration);


